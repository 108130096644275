/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./score-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./score-indicator.component";
var styles_ScoreIndicatorComponent = [i0.styles];
var RenderType_ScoreIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScoreIndicatorComponent, data: {} });
export { RenderType_ScoreIndicatorComponent as RenderType_ScoreIndicatorComponent };
function View_ScoreIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "%\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.score > 100) ? 100 : _co.score); _ck(_v, 1, 0, currVal_0); }); }
function View_ScoreIndicatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" No longer matched\n"]))], null, null); }
export function View_ScoreIndicatorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScoreIndicatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noLongerMatched", 2]], null, 0, null, View_ScoreIndicatorComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.score >= 0); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ScoreIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-score-indicator", [], [[8, "className", 0]], null, null, View_ScoreIndicatorComponent_0, RenderType_ScoreIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i3.ScoreIndicatorComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).cssClass; _ck(_v, 0, 0, currVal_0); }); }
var ScoreIndicatorComponentNgFactory = i1.ɵccf("caroo-score-indicator", i3.ScoreIndicatorComponent, View_ScoreIndicatorComponent_Host_0, { score: "score" }, {}, []);
export { ScoreIndicatorComponentNgFactory as ScoreIndicatorComponentNgFactory };
