import {Candidate} from '@caroo/shared/models';
import {Action} from '@ngrx/store';

export enum PipelineActionTypes {
	LoadMatches = '[Pipeline] Load Matches',
	SetMatches = '[Pipeline] Set Matches',
	LoadInterview = '[Pipeline] Load Interview',
	SetInterview = '[Pipeline] Set Interview',
	LoadHired = '[Pipeline] Load Hired',
	SetHired = '[Pipeline] Set Hired',
	LoadRejected = '[Pipeline] Load Rejected',
	LoadVerified = '[Pipeline] Load Verified',
	SetVerified = '[Pipeline] Set Verified',
	SetRejected = '[Pipeline] Set Rejected',
	LoadSavedForLater = '[Pipeline] Load Saved For Later',
	SetSavedForLater = '[Pipeline] Set Saved For Later',
	AddPipelineNote = '[Pipeline] Add Note',
	SetPipelineNote = '[Pipeline] Set Note',
	SetPipelineFilterId = '[Pipeline] Set Pipeline Filter Id',
	MoveCandidateToState = '[Pipeline] Move Candidate To State'
}

export class LoadPipelineMatches implements Action {
	readonly type = PipelineActionTypes.LoadMatches;

	constructor(public payload: { roleId: number }) {
	}
}

export class SetPipelineVerified implements Action {
	readonly type = PipelineActionTypes.SetVerified;

	constructor(public payload: {candidates: Candidate[]}) {
	}
}

export class SetPipelineMatches implements Action {
	readonly type = PipelineActionTypes.SetMatches;

	constructor(public payload: { candidates: Candidate[] }) {
	}
}

export class LoadPipelineInterview implements Action {
	readonly type = PipelineActionTypes.LoadInterview;

	constructor(public payload: { roleId: number }) {
	}
}

export class LoadPipelineVerified implements Action {
	readonly type = PipelineActionTypes.LoadVerified;

	constructor(public payload: {roleId: number}) {
	}
}

export class SetPipelineInterview implements Action {
	readonly type = PipelineActionTypes.SetInterview;

	constructor(public payload: { candidates: Candidate[] }) {
	}
}

export class LoadPipelineHired implements Action {
	readonly type = PipelineActionTypes.LoadHired;

	constructor(public payload: { roleId: number }) {
	}
}

export class SetPipelineHired implements Action {
	readonly type = PipelineActionTypes.SetHired;

	constructor(public payload: { candidates: Candidate[] }) {
	}
}

export class LoadPipelineRejected implements Action {
	readonly type = PipelineActionTypes.LoadRejected;

	constructor(public payload: { roleId: number }) {
	}
}

export class SetPipelineSavedForLater implements Action {
	readonly type = PipelineActionTypes.SetSavedForLater;

	constructor(public payload: { candidates: Candidate[] }) {
	}
}

export class LoadPipelineSavedForLater implements Action {
	readonly type = PipelineActionTypes.LoadSavedForLater;

	constructor(public payload: { roleId: number }) {
	}
}

export class SetPipelineRejected implements Action {
	readonly type = PipelineActionTypes.SetRejected;

	constructor(public payload: { candidates: Candidate[] }) {
	}
}

export class AddPipelineNote implements Action {
	readonly type = PipelineActionTypes.AddPipelineNote;

	constructor(public payload: { roleId: number, candidateId: number, text: string }) {
	}
}

export class SetPipelineNote implements Action {
	readonly type = PipelineActionTypes.SetPipelineNote;

	constructor(public payload: { candidate: Candidate }) {
	}
}

export class SetPipelineFilterId implements Action {
	readonly type = PipelineActionTypes.SetPipelineFilterId;

	constructor(public payload: { roleId?: number }) {
	}
}

export class MoveCandidateToState implements Action {
	readonly type = PipelineActionTypes.MoveCandidateToState;

	constructor(public payload: { roleId: number, candidateId: number, state: string, candidate: Candidate }) {
	}
}

export type PipelineActions =
	| LoadPipelineMatches
	| SetPipelineMatches
	| LoadPipelineInterview
	| SetPipelineInterview
	| LoadPipelineHired
	| LoadPipelineVerified
	| SetPipelineVerified
	| SetPipelineHired
	| LoadPipelineRejected
	| SetPipelineRejected
	| AddPipelineNote
	| SetPipelineNote
	| SetPipelineFilterId
	| SetPipelineSavedForLater
	| LoadPipelineSavedForLater
	| MoveCandidateToState;
