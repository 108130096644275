import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChange, SimpleChanges, ViewChild} from '@angular/core';
import { ImageResource } from '@caroo/graphql-new';
import {Resource} from '@caroo/shared/models';
import {ResourceConfig} from '@caroo/shared/resource/resource-config';
import {YoutubeService} from '@caroo/shared/youtube-input-field';
import {first} from 'rxjs/operators';
import {ResourceFragment} from '../../../generated/graphql';

type ResourceType = Resource | ResourceFragment | ImageResource | null;

@Component({
	selector: 'caroo-resource',
	templateUrl: './resource.component.html',
	styleUrls: ['./resource.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceComponent implements OnChanges, OnDestroy {
	private destroyed = false;
	@Input() resource?: ResourceType;
	@Input() fallback: Resource;
	@Input() config: ResourceConfig;
	internalConfig: ResourceConfig = {
		image: {
			container: {
				display: 'block'
			},
			width: undefined,
			height: undefined,
			objectFit: undefined
		},
		video: {
			width: '100%',
			height: '100%',
			controls: true
		},
		youtube: {
			showPreviewOnly: true
		}
	};
	Type = Resource.Type;
	@ViewChild('video', {static: false}) video: ElementRef<HTMLVideoElement>;

	showImage = false;
	showImageFallback = false;
	showVideoPlayIcon = false;

	constructor(private changeDetectorRef: ChangeDetectorRef, private youtubeService: YoutubeService) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['config'] && changes['config']['currentValue']) {
			this.internalConfig = {
				...this.internalConfig,
				...changes['config']['currentValue']
			};
		}
		let resource: Resource | ResourceFragment;
		let fallback: Resource;
		if (changes['resource'] && changes['resource']['currentValue']) {
			resource = changes['resource']['currentValue'] as Resource;
		}
		if (changes['fallback'] && changes['fallback']['currentValue']) {
			fallback = changes['fallback']['currentValue'] as Resource;
		}
		if (resource) {
			if (resource.type === Resource.Type.IMAGE || resource['__typename'] === 'ImageResource') {
				this.showImage = false;
				this.showImageFallback = false;
				const image = new Image();
				image.onload = () => {
					this.showImage = true;
					if (!this.destroyed) {
						this.changeDetectorRef.detectChanges();
					}
				};
				image.onerror = () => {
					this.showImageFallback = true;
					if (!this.destroyed) {
						this.changeDetectorRef.detectChanges();
					}
				};
				image.src = resource.resource ? resource.resource : resource['url'];
			} else if (resource.type === Resource.Type.VIDEO && this.video) {
				this.video.nativeElement.load();
			} else if (resource.type === Resource.Type.YOUTUBE) {
				if (this.internalConfig.youtube.showPreviewOnly === true) {
					this.showVideoPlayIcon = true;
					this.youtubeService.getVideoSnippet(resource.resource).pipe(first()).subscribe(data => {
						this.resource = {
							id: resource.id,
							type: Resource.Type.IMAGE,
							resource: data['items'][0]['snippet']['thumbnails']['medium']['url']
						};
						this.ngOnChanges({
							resource: new SimpleChange(resource, this.resource, false)
						});
					});
				}
			}
		} else if (fallback) {
			this.showImageFallback = true;
		}
	}

	ngOnDestroy() {
		this.destroyed = true;
	}
}
