import gql from 'graphql-tag';
import * as Apollo from 'apollo-angular';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
export var EmploymentTerm;
(function (EmploymentTerm) {
    EmploymentTerm["CONTRACT"] = "CONTRACT";
    EmploymentTerm["FREELANCE"] = "FREELANCE";
    EmploymentTerm["FULL_TIME"] = "FULL_TIME";
    EmploymentTerm["OTHER"] = "OTHER";
    EmploymentTerm["PART_TIME"] = "PART_TIME";
    EmploymentTerm["TEMPORARY"] = "TEMPORARY";
})(EmploymentTerm || (EmploymentTerm = {}));
export var ExperienceLevel;
(function (ExperienceLevel) {
    ExperienceLevel["JUNIOR"] = "JUNIOR";
    ExperienceLevel["MID_WEIGHT"] = "MID_WEIGHT";
    ExperienceLevel["SENIOR"] = "SENIOR";
})(ExperienceLevel || (ExperienceLevel = {}));
export var RoleRemovalReason;
(function (RoleRemovalReason) {
    RoleRemovalReason["CAROO"] = "CAROO";
    RoleRemovalReason["EXTERNAL"] = "EXTERNAL";
    RoleRemovalReason["UNNEEDED"] = "UNNEEDED";
})(RoleRemovalReason || (RoleRemovalReason = {}));
export const CandidateReachDataFragmentDoc = gql `
    fragment CandidateReachData on Query {
  numCandidates
  candidateReach(input: {skillIds: $skillIds, industryExperienceIds: $industryExperienceIds, location: $location}) {
    skills {
      skill {
        id
        name
      }
      reach
    }
    industryExperiences {
      industryExperience {
        id
        name
      }
      reach
    }
    location {
      reach
    }
    totalReach
  }
}
    `;
export const RoleFragmentDoc = gql `
    fragment Role on Role {
  id
  stats {
    numSuggested
    numInterested
    numInConversation
    numInterviews
    numOfferMade
    numArchived
    numVerified
  }
}
    `;
export const ProfessionFragmentDoc = gql `
    fragment Profession on Profession {
  id
  name
}
    `;
export const SkillFragmentDoc = gql `
    fragment Skill on Skill {
  id
  name
}
    `;
export const AcceptBrandInviteDocument = gql `
    mutation AcceptBrandInvite($input: AcceptBrandInviteInput!) {
  acceptBrandInvite(input: $input) {
    employee {
      id
    }
  }
}
    `;
export class AcceptBrandInviteGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = AcceptBrandInviteDocument;
    }
}
AcceptBrandInviteGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AcceptBrandInviteGQL_Factory() { return new AcceptBrandInviteGQL(i0.ɵɵinject(i1.Apollo)); }, token: AcceptBrandInviteGQL, providedIn: "root" });
export const GetCandidateReachDocument = gql `
    query GetCandidateReach($skillIds: [Int!]!, $industryExperienceIds: [Int!]!, $location: CandidateReachLocationInput) {
  ...CandidateReachData
}
    ${CandidateReachDataFragmentDoc}`;
export class GetCandidateReachGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetCandidateReachDocument;
    }
}
GetCandidateReachGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetCandidateReachGQL_Factory() { return new GetCandidateReachGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetCandidateReachGQL, providedIn: "root" });
export const GetProfileDocument = gql `
    query GetProfile {
  colleagues {
    id
    name
    email
    avatar {
      id
      url
    }
  }
  colleagueInvites {
    email
  }
}
    `;
export class GetProfileGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetProfileDocument;
    }
}
GetProfileGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetProfileGQL_Factory() { return new GetProfileGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetProfileGQL, providedIn: "root" });
export const InviteColleagueDocument = gql `
    mutation InviteColleague($input: InviteColleagueInput!) {
  inviteColleague(input: $input) {
    colleagueInvites {
      email
    }
  }
}
    `;
export class InviteColleagueGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = InviteColleagueDocument;
    }
}
InviteColleagueGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InviteColleagueGQL_Factory() { return new InviteColleagueGQL(i0.ɵɵinject(i1.Apollo)); }, token: InviteColleagueGQL, providedIn: "root" });
export const RemoveRoleDocument = gql `
    mutation RemoveRole($roleId: Int!, $removalReason: RoleRemovalReason!) {
  removeRole(input: {roleId: $roleId, removalReason: $removalReason}) {
    brand {
      id
    }
  }
}
    `;
export class RemoveRoleGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = RemoveRoleDocument;
    }
}
RemoveRoleGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoveRoleGQL_Factory() { return new RemoveRoleGQL(i0.ɵɵinject(i1.Apollo)); }, token: RemoveRoleGQL, providedIn: "root" });
export const GetRoleDocument = gql `
    query getRole($input: RoleInput!) {
  role(input: $input) {
    ...Role
  }
}
    ${RoleFragmentDoc}`;
export class GetRoleGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetRoleDocument;
    }
}
GetRoleGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetRoleGQL_Factory() { return new GetRoleGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetRoleGQL, providedIn: "root" });
export const SearchProfessionsDocument = gql `
    query SearchProfessions($query: String!) {
  searchProfessions(input: {query: $query}) {
    ...Profession
  }
}
    ${ProfessionFragmentDoc}`;
export class SearchProfessionsGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = SearchProfessionsDocument;
    }
}
SearchProfessionsGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchProfessionsGQL_Factory() { return new SearchProfessionsGQL(i0.ɵɵinject(i1.Apollo)); }, token: SearchProfessionsGQL, providedIn: "root" });
export const SuggestSkillsDocument = gql `
    query SuggestSkills($professionId: Int!) {
  suggestSkills(input: {professionId: $professionId}) {
    ...Skill
  }
}
    ${SkillFragmentDoc}`;
export class SuggestSkillsGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = SuggestSkillsDocument;
    }
}
SuggestSkillsGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SuggestSkillsGQL_Factory() { return new SuggestSkillsGQL(i0.ɵɵinject(i1.Apollo)); }, token: SuggestSkillsGQL, providedIn: "root" });
export const SearchSkillsDocument = gql `
    query SearchSkills($query: String!) {
  searchSkills(input: {query: $query}) {
    ...Skill
  }
}
    ${SkillFragmentDoc}`;
export class SearchSkillsGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = SearchSkillsDocument;
    }
}
SearchSkillsGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchSkillsGQL_Factory() { return new SearchSkillsGQL(i0.ɵɵinject(i1.Apollo)); }, token: SearchSkillsGQL, providedIn: "root" });
