import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {map, switchMap, throttleTime, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../../store/reducers';
import {RoleActions, RoleActionTypes, SetAllRoleTitles, SetLiveRoles, SetRole, SetRoleTitles, SetSavedRoles, SetScheduledRoles} from '../actions/role.actions';
import {RoleService} from '../services/role.service';

@Injectable()
export class RoleEffects {
	@Effect()
	loadLiveRoles$ = this.actions$
		.pipe(ofType(RoleActionTypes.LoadLiveRoles, RoleActionTypes.SetRolesScope))
		.pipe(withLatestFrom(this.store$))
		.pipe(switchMap(([, state]) => this.roleService.getRoles(state.roles.rolesScope, 'live')))
		.pipe(map(roles => new SetLiveRoles({roles})));

	@Effect()
	loadSavedRoles$ = this.actions$
		.pipe(ofType(RoleActionTypes.LoadSavedRoles, RoleActionTypes.SetRolesScope))
		.pipe(withLatestFrom(this.store$))
		.pipe(switchMap(([, state]) => this.roleService.getRoles(state.roles.rolesScope, 'saved')))
		.pipe(map(roles => new SetSavedRoles({roles})));

	@Effect()
	loadScheduledRoles$ = this.actions$
		.pipe(ofType(RoleActionTypes.LoadScheduledRoles, RoleActionTypes.SetRolesScope))
		.pipe(withLatestFrom(this.store$))
		.pipe(switchMap(([, state]) => this.roleService.getRoles(state.roles.rolesScope, 'scheduled')))
		.pipe(map(roles => new SetScheduledRoles({roles})));

	@Effect()
	loadRoleTitles$ = this.actions$
		.pipe(ofType(RoleActionTypes.LoadRoleTitles))
		.pipe(switchMap(() => this.roleService.getRoleTitles()))
		.pipe(map(roleTitles => new SetRoleTitles({roleTitles})));

	@Effect()
	loadAllRoleTitles$ = this.actions$
		.pipe(ofType(RoleActionTypes.LoadAllRoleTitles))
		.pipe(switchMap(() => this.roleService.getAllRoleTitles()))
		.pipe(map(allRoleTitles => new SetAllRoleTitles({allRoleTitles})));

	@Effect()
	loadRole$ = this.actions$
		.pipe(ofType(RoleActionTypes.LoadRole))
		.pipe(throttleTime(1000))
		.pipe(switchMap(action => this.roleService.getRole(action.payload.id)))
		.pipe(map(role => new SetRole({role})));

	constructor(private store$: Store<AppState>, private actions$: Actions<RoleActions>, private roleService: RoleService) {
	}
}
