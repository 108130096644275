var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Noop } from './../../store/actions/app.actions';
import { PipelineService } from './../../pipeline/services/pipeline.service';
import { CandidateActionTypes, LoadCandidate, LoadCandidateStateCounts, SetBrandInterestedCandidates, SetCandidate, SetCandidateInterestedCandidates, SetCandidateStateCounts, SetMatchedCandidates, SetSuggestedCandidates } from '@caroo/candidates/actions/candidate.actions';
import { CandidateService } from '@caroo/candidates/services/candidate.service';
import { UpdateChatScore } from '@caroo/messages/actions/message.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, zip } from 'rxjs';
import { map, switchMap, throttleTime } from 'rxjs/operators';
export class CandidateEffects {
    // tslint:disable-next-line: max-line-length
    constructor(actions$, candidateService, pipelineService) {
        this.actions$ = actions$;
        this.candidateService = candidateService;
        this.pipelineService = pipelineService;
        this.loadCandidateStateCounts$ = this.actions$
            .pipe(ofType(CandidateActionTypes.LoadCandidateStateCounts))
            .pipe(throttleTime(1000))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.candidateService.getCandidateStateCounts(action.payload.roleId))))
            .pipe(map(([roleId, candidateStateCounts]) => new SetCandidateStateCounts({ roleId, candidateStateCounts })));
        this.loadMatchedCandidates$ = this.actions$
            .pipe(ofType(CandidateActionTypes.LoadMatchedCandidates))
            .pipe(throttleTime(1000))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.candidateService.getMatchedCandidates(action.payload.roleId, action.payload.offset, action.payload.limit))))
            .pipe(map(([roleId, candidates]) => new SetMatchedCandidates({ roleId, candidates })));
        this.loadBrandInterestedCandidates$ = this.actions$
            .pipe(ofType(CandidateActionTypes.LoadBrandInterestedCandidates))
            .pipe(throttleTime(1000))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.candidateService.getBrandInterestedCandidates(action.payload.roleId, action.payload.offset, action.payload.limit))))
            .pipe(map(([roleId, candidates]) => new SetBrandInterestedCandidates({ roleId, candidates })));
        this.loadCandidateInterestedCandidates$ = this.actions$
            .pipe(ofType(CandidateActionTypes.LoadCandidateInterestedCandidates))
            .pipe(throttleTime(1000))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.candidateService.getCandidateInterestedCandidates(action.payload.roleId, action.payload.offset, action.payload.limit))))
            .pipe(map(([roleId, candidates]) => new SetCandidateInterestedCandidates({ roleId, candidates })));
        this.loadSuggestedCandidates$ = this.actions$
            .pipe(ofType(CandidateActionTypes.LoadSuggestedCandidates))
            .pipe(throttleTime(1000))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.candidateService.getSuggestedCandidates(action.payload.roleId, action.payload.offset, action.payload.limit))))
            .pipe(map(([roleId, candidates]) => new SetSuggestedCandidates({ roleId, candidates })));
        this.expressInterestInCandidate$ = this.actions$
            .pipe(ofType(CandidateActionTypes.ChangeCandidateStateFromCandidateInterestedToMatched, CandidateActionTypes.ChangeCandidateStateFromSuggestedToBrandInterested))
            .pipe(switchMap(action => zip(of(action), this.candidateService.expressInterestInCandidate(action.payload.roleId, action.payload.candidate.id))))
            .pipe(switchMap(([{ payload }]) => [
            new LoadCandidate({ roleId: payload.roleId, candidateId: payload.candidate.id }),
            new LoadCandidateStateCounts({ roleId: payload.roleId })
        ]));
        this.rejectCandidate$ = this.actions$
            .pipe(ofType(CandidateActionTypes.ChangeCandidateStateFromCandidateInterestedToBrandRejected, CandidateActionTypes.ChangeCandidateStateFromSuggestedToBrandRejected))
            .pipe(switchMap(action => zip(of(action), this.candidateService.rejectCandidate(action.payload.roleId, action.payload.candidate.id), this.pipelineService.moveCandidateToState(action.payload.roleId, action.payload.candidate.id, "REJECTED"))))
            .pipe(switchMap(([{ payload }]) => [
            new LoadCandidate({ roleId: payload.roleId, candidateId: payload.candidate.id }),
            new LoadCandidateStateCounts({ roleId: payload.roleId })
        ]));
        this.loadCandidate$ = this.actions$
            .pipe(ofType(CandidateActionTypes.LoadCandidate))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.candidateService.getCandidate(action.payload.roleId, action.payload.candidateId))))
            .pipe(switchMap(([roleId, candidate]) => [
            new SetCandidate({ roleId, candidate }),
            new UpdateChatScore({ roleId, candidateId: candidate.id, score: candidate.percentageMatch })
        ]));
        this.addNoteToCandidate$ = this.actions$
            .pipe(ofType(CandidateActionTypes.AddNoteToCandidate))
            .pipe(switchMap(action => zip(of(action), this.candidateService.addNoteToCandidate(action.payload.roleId, action.payload.candidateId, action.payload.text))))
            .pipe(map(([action]) => new LoadCandidate({ roleId: action.payload.roleId, candidateId: action.payload.candidateId })));
        this.changeCandidateState$ = this.actions$
            .pipe(ofType(CandidateActionTypes.ChangeCandidateState))
            .pipe(throttleTime(1000))
            .pipe(switchMap(action => zip(of(action), this.pipelineService.moveCandidateToState(action.payload.roleId, action.payload.candidateId, action.payload.state))))
            .pipe(map(([action]) => new Noop()));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "loadCandidateStateCounts$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "loadMatchedCandidates$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "loadBrandInterestedCandidates$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "loadCandidateInterestedCandidates$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "loadSuggestedCandidates$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "expressInterestInCandidate$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "rejectCandidate$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "loadCandidate$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "addNoteToCandidate$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CandidateEffects.prototype, "changeCandidateState$", void 0);
