import { HttpClient } from '@angular/common/http';
import { GetRoleGQL, USE_BRAND_API } from '@caroo/graphql-new';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../graphql-new/generated/graphql";
export class RoleService {
    constructor(http, getRoleGQL) {
        this.http = http;
        this.getRoleGQL = getRoleGQL;
    }
    createRole(body) {
        return this.http.post(`${environment.baseUrl}/roles`, body);
    }
    updateRole(id, body) {
        return this.http.patch(`${environment.baseUrl}/roles/${id}`, body);
    }
    updateRoleSchedule(id, body) {
        return this.http.put(`${environment.baseUrl}/roles/${id}/schedule`, body, {
            responseType: 'text'
        });
    }
    getRoleTitles() {
        return this.http.get(`${environment.baseUrl}/roles/titles`);
    }
    getAllRoleTitles() {
        return this.http.get(`${environment.baseUrl}/roles/titles/all`);
    }
    getRoles(scope, state) {
        return this.http.get(`${environment.baseUrl}/roles?scope=${scope}&state=${state}`);
    }
    getRole(id) {
        return this.http.get(`${environment.baseUrl}/roles/${id}`);
    }
    transferRoles(colleagueProfileId, roleIds) {
        return this.http.post(`${environment.baseUrl}/roles/transfer`, { colleagueProfileId, roleIds });
    }
    getRoleById(roleId) {
        return this.getRoleGQL
            .watch({ input: { roleId } }, USE_BRAND_API)
            .valueChanges
            .pipe(map(result => result.data.role));
    }
}
RoleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleService_Factory() { return new RoleService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GetRoleGQL)); }, token: RoleService, providedIn: "root" });
