import {Role} from '../../shared/models';
import {RoleActions, RoleActionTypes} from '../actions/role.actions';

export interface RoleState {
	rolesScope: 'all' | 'own';
	liveRoles: Role[];
	savedRoles: Role[];
	scheduledRoles: Role[];
	roleTitles: Role[];
	allRoleTitles: Role[];
	roles: { [s: string]: Role };
	addedRoleId?: number;
	rolesWithActions?: Role[];
}

export const initialState: RoleState = {
	rolesScope: 'all',
	liveRoles: [],
	savedRoles: [],
	scheduledRoles: [],
	roleTitles: [],
	allRoleTitles: [],
	roles: {},
	rolesWithActions: [],
};

export function reducer(state = initialState, action: RoleActions): RoleState {
	switch (action.type) {
		case RoleActionTypes.SetRolesScope:
			return {
				...state,
				rolesScope: action.payload.rolesScope
			};
		case RoleActionTypes.SetLiveRoles:
			return {
				...state,
				liveRoles: action.payload.roles
			};
		case RoleActionTypes.SetSavedRoles:
			return {
				...state,
				savedRoles: action.payload.roles
			};
		case RoleActionTypes.SetScheduledRoles:
			return {
				...state,
				scheduledRoles: action.payload.roles
			};
		case RoleActionTypes.SetRoleTitles:
			return {
				...state,
				roleTitles: action.payload.roleTitles
			};
		case RoleActionTypes.SetAllRoleTitles:
			return {
				...state,
				allRoleTitles: action.payload.allRoleTitles
			};
		case RoleActionTypes.SetRole:
			return {
				...state,
				roles: {
					...state.roles,
					[action.payload.role.id]: action.payload.role
				}
			};
		case RoleActionTypes.SetAddedRoleId:
			return {
				...state,
				addedRoleId: action.payload.roleId
			};
		case RoleActionTypes.SetRolesWithActions:
			return {
				...state,
				rolesWithActions: action.payload.roles.filter(e => e.numInterested > 0)
			};
		default:
			return state;
	}
}
