import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {RoleEffects} from './effects/role.effects';
import {reducer} from './reducers/role.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('roles', reducer),
		EffectsModule.forFeature([RoleEffects])
	]
})
export class RoleStateModule {
}
