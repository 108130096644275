var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CandidateService } from '@caroo/candidates/services/candidate.service';
// tslint:disable-next-line: max-line-length
import { PipelineActionTypes, SetPipelineHired, SetPipelineInterview, SetPipelineMatches, SetPipelineRejected, SetPipelineVerified } from '@caroo/pipeline/actions/pipeline.actions';
import { Noop } from '@caroo/store/actions/app.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, zip } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { SetPipelineNote, SetPipelineSavedForLater } from './../actions/pipeline.actions';
import { PipelineService } from './../services/pipeline.service';
export class PipelineEffects {
    // tslint:disable-next-line: max-line-length
    constructor(actions$, candidateService, pipelineService) {
        this.actions$ = actions$;
        this.candidateService = candidateService;
        this.pipelineService = pipelineService;
        this.loadMatches$ = this.actions$
            .pipe(ofType(PipelineActionTypes.LoadMatches))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.pipelineService.getPipelineCandidates(action.payload.roleId, 'MATCHED'))))
            .pipe(map(([id, candidates]) => new SetPipelineMatches({ candidates: candidates })));
        this.loadInterview$ = this.actions$
            .pipe(ofType(PipelineActionTypes.LoadInterview))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.pipelineService.getPipelineCandidates(action.payload.roleId, 'INTERVIEW'))))
            .pipe(map(([id, candidates]) => new SetPipelineInterview({ candidates: candidates })));
        this.loadHired$ = this.actions$
            .pipe(ofType(PipelineActionTypes.LoadHired))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.pipelineService.getPipelineCandidates(action.payload.roleId, 'HIRED'))))
            .pipe(map(([id, candidates]) => new SetPipelineHired({ candidates: candidates })));
        this.loadRejected$ = this.actions$
            .pipe(ofType(PipelineActionTypes.LoadRejected))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.pipelineService.getPipelineCandidates(action.payload.roleId, 'REJECTED'))))
            .pipe(map(([id, candidates]) => new SetPipelineRejected({ candidates: candidates })));
        this.loadVerified$ = this.actions$
            .pipe(ofType(PipelineActionTypes.LoadVerified))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.pipelineService.getPipelineCandidates(action.payload.roleId, "VERIFIED"))))
            .pipe(map(([id, candidates]) => new SetPipelineVerified({ candidates })));
        this.loadSavedForLater$ = this.actions$
            .pipe(ofType(PipelineActionTypes.LoadSavedForLater))
            .pipe(switchMap(action => zip(of(action.payload.roleId), this.pipelineService.getPipelineCandidates(action.payload.roleId, 'SAVED_FOR_LATER'))))
            .pipe(map(([id, candidates]) => new SetPipelineSavedForLater({ candidates: candidates })));
        this.addNoteToCandidate$ = this.actions$
            .pipe(ofType(PipelineActionTypes.AddPipelineNote))
            .pipe(switchMap(action => zip(of(action), this.candidateService.addNoteToCandidate(action.payload.roleId, action.payload.candidateId, action.payload.text))))
            .pipe(switchMap(([action]) => zip(of(action), this.candidateService.getCandidate(action.payload.roleId, action.payload.candidateId))))
            .pipe(map(([action, candidate]) => new SetPipelineNote({ candidate: candidate })));
        this.moveCandidate$ = this.actions$
            .pipe(ofType(PipelineActionTypes.MoveCandidateToState))
            .pipe(filter(action => action.payload.candidate.pipelineState !== action.payload.state))
            .pipe(switchMap(action => zip(of(action), this.pipelineService.moveCandidateToState(action.payload.roleId, action.payload.candidateId, action.payload.state))
            .pipe(switchMap(([action, res]) => zip(of(res), this.candidateService.expressInterestInCandidate(action.payload.roleId, action.payload.candidateId))))))
            .pipe(map(() => new Noop()));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], PipelineEffects.prototype, "loadMatches$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], PipelineEffects.prototype, "loadInterview$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], PipelineEffects.prototype, "loadHired$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], PipelineEffects.prototype, "loadRejected$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], PipelineEffects.prototype, "loadVerified$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], PipelineEffects.prototype, "loadSavedForLater$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], PipelineEffects.prototype, "addNoteToCandidate$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], PipelineEffects.prototype, "moveCandidate$", void 0);
