import {Action} from '@ngrx/store';
import {Chat, Message} from '../models';

export enum MessageActionTypes {
	LoadChats = '[Message] Load Chats',
	SetChats = '[Message] Set Chats',
	LoadChat = '[Message] Load Chat',
	SetChat = '[Message] Set Chat',
	SelectChat = '[Message] Select Chat',
	SetChatRoleFilter = '[Message] Set Chat Role Filter',
	LoadMessages = '[Message] Load Messages',
	SetMessages = '[Message] Set Messages',
	SendMessage = '[Message] Add Message',
	UpdateChatScore = '[Message] Update Chat Score'
}

export class LoadChats implements Action {
	readonly type = MessageActionTypes.LoadChats;

	constructor(public payload: { selectFirstChat: boolean } = {selectFirstChat: false}) {
	}
}

export class SetChats implements Action {
	readonly type = MessageActionTypes.SetChats;

	constructor(public payload: { chats: Chat[] }) {
	}
}

export class LoadChat implements Action {
	readonly type = MessageActionTypes.LoadChat;

	constructor(public payload: { id: number }) {
	}
}

export class SetChat implements Action {
	readonly type = MessageActionTypes.SetChat;

	constructor(public payload: { chat: Chat }) {
	}
}

export class SelectChat implements Action {
	readonly type = MessageActionTypes.SelectChat;

	constructor(public payload: { chatId: number }) {
	}
}

export class SetChatRoleFilter implements Action {
	readonly type = MessageActionTypes.SetChatRoleFilter;

	constructor(public payload: { roleId?: number }) {
	}
}

export class LoadMessages implements Action {
	readonly type = MessageActionTypes.LoadMessages;

	constructor(public payload: { chatId: number }) {
	}
}

export class SetMessages implements Action {
	readonly type = MessageActionTypes.SetMessages;

	constructor(public payload: { chatId: number, messages: Message[] }) {
	}
}

export class SendMessage implements Action {
	readonly type = MessageActionTypes.SendMessage;

	constructor(public payload: { chatId: number, message: string }) {
	}
}

export class UpdateChatScore implements Action {
	readonly type = MessageActionTypes.UpdateChatScore;

	constructor(public payload: { roleId: number, candidateId: number, score: number }) {
	}
}


export type MessageActions =
	| LoadChats
	| SetChats
	| LoadChat
	| SetChat
	| SelectChat
	| SetChatRoleFilter
	| LoadMessages
	| SetMessages
	| UpdateChatScore;
