import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { GetRoleGQL, USE_BRAND_API } from '@caroo/graphql-new';
import {Schedule} from '@caroo/roles/components/schedule-editor/schedule';
import {EmploymentTerm} from '@caroo/shared/models/employment-term';
import {ExperienceLevel} from '@caroo/shared/models/experience-level';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Resource, Role} from '../../shared/models';

@Injectable({
	providedIn: 'root'
})
export class RoleService {

	constructor(private http: HttpClient, private getRoleGQL: GetRoleGQL) {
	}

	createRole(body: CreateUpdateRoleBody): Observable<Role> {
		return this.http.post<Role>(`${environment.baseUrl}/roles`, body);
	}

	updateRole(id: number, body: CreateUpdateRoleBody): Observable<Role> {
		return this.http.patch<Role>(`${environment.baseUrl}/roles/${id}`, body);
	}

	updateRoleSchedule(id: number, body: Schedule): Observable<any> {
		return this.http.put(`${environment.baseUrl}/roles/${id}/schedule`, body, {
			responseType: 'text'
		});
	}

	getRoleTitles(): Observable<Role[]> {
		return this.http.get<Role[]>(`${environment.baseUrl}/roles/titles`);
	}

	getAllRoleTitles(): Observable<Role[]> {
		return this.http.get<Role[]>(`${environment.baseUrl}/roles/titles/all`);
	}

	getRoles(scope: 'all' | 'own', state: 'live' | 'saved' | 'scheduled'): Observable<Role[]> {
		return this.http.get<Role[]>(`${environment.baseUrl}/roles?scope=${scope}&state=${state}`);
	}

	getRole(id: number): Observable<Role> {
		return this.http.get<Role>(`${environment.baseUrl}/roles/${id}`);
	}

	transferRoles(colleagueProfileId: number, roleIds: number[]) {
		return this.http.post(`${environment.baseUrl}/roles/transfer`, {colleagueProfileId, roleIds});
	}

	getRoleById(roleId: number) {
		return this.getRoleGQL
			.watch({input: {roleId}}, USE_BRAND_API)
			.valueChanges
			.pipe(map(result => result.data.role))
	}
}

export interface CreateUpdateRoleBody {
	title?: string;
	locationId?: number;
	isRemoteWorkAllowed?: boolean;
	description?: string;
	videoDescription?: Resource;
	employmentTerm?: EmploymentTerm;
	minSalary?: number;
	maxSalary?: number;
	hideSalary?: boolean;
	alternateSalaryText?: string;
	type?: number;
	professionId?: number;
	skills?: { id: number; name: string; required: boolean }[];
	industryExperiences?: { id: number; name: string; required: boolean }[];
	experienceLevel?: ExperienceLevel;
	description1?: string;
	description2?: string;
	pinned?: boolean;
}
