import {Injectable} from '@angular/core';
import {CandidateService} from '@caroo/candidates/services/candidate.service';
// tslint:disable-next-line: max-line-length
import {PipelineActions, PipelineActionTypes, SetPipelineHired, SetPipelineInterview, SetPipelineMatches, SetPipelineRejected, SetPipelineVerified} from '@caroo/pipeline/actions/pipeline.actions';
import { State } from '@caroo/shared/models';
import {Noop} from '@caroo/store/actions/app.actions';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of, zip} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {SetPipelineNote, SetPipelineSavedForLater} from './../actions/pipeline.actions';
import {PipelineService} from './../services/pipeline.service';

@Injectable()
export class PipelineEffects {
	@Effect()
	loadMatches$ = this.actions$
		.pipe(ofType(PipelineActionTypes.LoadMatches))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.pipelineService.getPipelineCandidates(action.payload.roleId, 'MATCHED')))
		)
		.pipe(map(([id, candidates]) => new SetPipelineMatches({candidates: candidates})));

	@Effect()
	loadInterview$ = this.actions$
		.pipe(ofType(PipelineActionTypes.LoadInterview))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.pipelineService.getPipelineCandidates(action.payload.roleId, 'INTERVIEW')))
		)
		.pipe(map(([id, candidates]) => new SetPipelineInterview({candidates: candidates})));

	@Effect()
	loadHired$ = this.actions$
		.pipe(ofType(PipelineActionTypes.LoadHired))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.pipelineService.getPipelineCandidates(action.payload.roleId, 'HIRED')))
		)
		.pipe(map(([id, candidates]) => new SetPipelineHired({candidates: candidates})));

	@Effect()
	loadRejected$ = this.actions$
		.pipe(ofType(PipelineActionTypes.LoadRejected))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.pipelineService.getPipelineCandidates(action.payload.roleId, 'REJECTED')))
		)
		.pipe(map(([id, candidates]) => new SetPipelineRejected({candidates: candidates})));

	@Effect()
	loadVerified$ = this.actions$
			.pipe(ofType(PipelineActionTypes.LoadVerified))
			.pipe(switchMap(action => zip(
				of(action.payload.roleId),
				this.pipelineService.getPipelineCandidates(action.payload.roleId, "VERIFIED")
			)))
			.pipe(map(([id, candidates]) => new SetPipelineVerified({candidates}))) 

	@Effect()
	loadSavedForLater$ = this.actions$
		.pipe(ofType(PipelineActionTypes.LoadSavedForLater))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.pipelineService.getPipelineCandidates(action.payload.roleId, 'SAVED_FOR_LATER')))
		)
		.pipe(map(([id, candidates]) => new SetPipelineSavedForLater({candidates: candidates})));

	@Effect()
	addNoteToCandidate$ = this.actions$
		.pipe(ofType(PipelineActionTypes.AddPipelineNote))
		.pipe(switchMap(action => zip(
			of(action), this.candidateService.addNoteToCandidate(action.payload.roleId, action.payload.candidateId, action.payload.text)),
		))
		.pipe(switchMap(([action]) => zip(
			of(action), this.candidateService.getCandidate(action.payload.roleId, action.payload.candidateId)
		)))
		.pipe(map(([action, candidate]) => new SetPipelineNote({candidate: candidate})));

	@Effect()
	moveCandidate$ = this.actions$
		.pipe(ofType(PipelineActionTypes.MoveCandidateToState))
		.pipe(filter(action => action.payload.candidate.pipelineState !== action.payload.state))
		.pipe(switchMap(action => zip(
			of(action), this.pipelineService.moveCandidateToState(action.payload.roleId, action.payload.candidateId, action.payload.state
		))
		.pipe(switchMap(([action, res]) => zip(
			of(res), this.candidateService.expressInterestInCandidate(action.payload.roleId, action.payload.candidateId)
		)))
		))
		.pipe(map(() => new Noop()));

	// tslint:disable-next-line: max-line-length
	constructor(private actions$: Actions<PipelineActions>, private candidateService: CandidateService, private pipelineService: PipelineService) {
	}

}
