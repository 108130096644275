import {CandidateStateCounts} from '@caroo/candidates/services/candidate.service';
import {Action} from '@ngrx/store';
import {Candidate} from '../../shared/models';

export enum CandidateActionTypes {
	LoadCandidateStateCounts = '[Candidate] Load Candidate State Counts',
	SetCandidateStateCounts = '[Candidate] Set Candidate State Counts',
	ClearMatchedCandidates = '[Candidate] Clear Matched Candidates',
	LoadMatchedCandidates = '[Candidate] Load Matched Candidates',
	SetMatchedCandidates = '[Candidate] Set Matched Candidates',
	ClearBrandInterestedCandidates = '[Candidate] Clear Brand Interested Candidates',
	LoadBrandInterestedCandidates = '[Candidate] Load Brand Interested Candidates',
	SetBrandInterestedCandidates = '[Candidate] Set Brand Interested Candidates',
	ClearCandidateInterestedCandidates = '[Candidate] Clear Candidate Interested Candidates',
	LoadCandidateInterestedCandidates = '[Candidate] Load Candidate Interested Candidates',
	SetCandidateInterestedCandidates = '[Candidate] Set Candidate Interested Candidates',
	ClearSuggestedCandidates = '[Candidate] Clear Suggested Candidates',
	LoadSuggestedCandidates = '[Candidate] Load Suggested Candidates',
	SetSuggestedCandidates = '[Candidate] Set Suggested Candidates',
	LoadCandidate = '[Candidate] Load Candidate',
	SetCandidate = '[Candidate] Set Candidate',
	ChangeCandidateStateFromCandidateInterestedToMatched = '[Candidate] Change Candidate State Fom Candidate Interested To Matched',
	// tslint:disable-next-line: max-line-length
	ChangeCandidateStateFromCandidateInterestedToBrandRejected = '[Candidate] Change Candidate State Fom Candidate Interested To Brand Rejected',
	ChangeCandidateStateFromSuggestedToBrandInterested = '[Candidate] Change Candidate State Fom Suggested To Brand Interested',
	ChangeCandidateStateFromSuggestedToBrandRejected = '[Candidate] Change Candidate State Fom Suggested To Brand Rejected',
	AddNoteToCandidate = '[Candidate] Add Note to Candidate',
	ChangeCandidateState = '[Candidate] Change Candidate State'
}

export class LoadCandidateStateCounts implements Action {
	readonly type = CandidateActionTypes.LoadCandidateStateCounts;

	constructor(public payload: { roleId: number }) {
	}
}

export class SetCandidateStateCounts implements Action {
	readonly type = CandidateActionTypes.SetCandidateStateCounts;

	constructor(public payload: { roleId: number, candidateStateCounts: CandidateStateCounts }) {
	}
}

export class ClearMatchedCandidates implements Action {
	readonly type = CandidateActionTypes.ClearMatchedCandidates;

	constructor(public payload: { roleId: number }) {
	}
}

export class LoadMatchedCandidates implements Action {
	readonly type = CandidateActionTypes.LoadMatchedCandidates;

	constructor(public payload: { roleId: number, limit: number, offset: number }) {
	}
}

export class SetMatchedCandidates implements Action {
	readonly type = CandidateActionTypes.SetMatchedCandidates;

	constructor(public payload: { roleId: number, candidates: Candidate[] }) {
	}
}

export class ClearBrandInterestedCandidates implements Action {
	readonly type = CandidateActionTypes.ClearBrandInterestedCandidates;

	constructor(public payload: { roleId: number }) {
	}
}

export class LoadBrandInterestedCandidates implements Action {
	readonly type = CandidateActionTypes.LoadBrandInterestedCandidates;

	constructor(public payload: { roleId: number, limit: number, offset: number }) {
	}
}

export class SetBrandInterestedCandidates implements Action {
	readonly type = CandidateActionTypes.SetBrandInterestedCandidates;

	constructor(public payload: { roleId: number, candidates: Candidate[] }) {
	}
}

export class ClearCandidateInterestedCandidates implements Action {
	readonly type = CandidateActionTypes.ClearCandidateInterestedCandidates;

	constructor(public payload: { roleId: number }) {
	}
}

export class LoadCandidateInterestedCandidates implements Action {
	readonly type = CandidateActionTypes.LoadCandidateInterestedCandidates;

	constructor(public payload: { roleId: number, limit: number, offset: number }) {
	}
}

export class SetCandidateInterestedCandidates implements Action {
	readonly type = CandidateActionTypes.SetCandidateInterestedCandidates;

	constructor(public payload: { roleId: number, candidates: Candidate[] }) {
	}
}

export class ClearSuggestedCandidates implements Action {
	readonly type = CandidateActionTypes.ClearSuggestedCandidates;

	constructor(public payload: { roleId: number }) {
	}
}

export class LoadSuggestedCandidates implements Action {
	readonly type = CandidateActionTypes.LoadSuggestedCandidates;

	constructor(public payload: { roleId: number, limit: number, offset: number }) {
	}
}

export class SetSuggestedCandidates implements Action {
	readonly type = CandidateActionTypes.SetSuggestedCandidates;

	constructor(public payload: { roleId: number, candidates: Candidate[] }) {
	}
}

export class LoadCandidate implements Action {
	readonly type = CandidateActionTypes.LoadCandidate;

	constructor(public payload: { roleId: number, candidateId: number }) {
	}
}

export class SetCandidate implements Action {
	readonly type = CandidateActionTypes.SetCandidate;

	constructor(public payload: { roleId: number, candidate: Candidate }) {
	}
}

export class ChangeCandidateStateFromCandidateInterestedToMatched implements Action {
	readonly type = CandidateActionTypes.ChangeCandidateStateFromCandidateInterestedToMatched;

	constructor(public payload: { roleId: number, candidate: Candidate }) {
	}
}

export class ChangeCandidateStateFromCandidateInterestedToBrandRejected implements Action {
	readonly type = CandidateActionTypes.ChangeCandidateStateFromCandidateInterestedToBrandRejected;

	constructor(public payload: { roleId: number, candidate: Candidate }) {
	}
}

export class ChangeCandidateStateFromSuggestedToBrandInterested implements Action {
	readonly type = CandidateActionTypes.ChangeCandidateStateFromSuggestedToBrandInterested;

	constructor(public payload: { roleId: number, candidate: Candidate }) {
	}
}

export class ChangeCandidateStateFromSuggestedToBrandRejected implements Action {
	readonly type = CandidateActionTypes.ChangeCandidateStateFromSuggestedToBrandRejected;

	constructor(public payload: { roleId: number, candidate: Candidate }) {
	}
}

export class AddNoteToCandidate implements Action {
	readonly type = CandidateActionTypes.AddNoteToCandidate;

	constructor(public payload: { roleId: number, candidateId: number, text: string }) {
	}
}

export class ChangeCandidateState implements Action {
	readonly type = CandidateActionTypes.ChangeCandidateState;

	constructor(public payload: { roleId: number, candidateId: number, state: string }) {
	}
}

export type CandidateActions =
	| LoadCandidateStateCounts
	| SetCandidateStateCounts
	| ClearMatchedCandidates
	| LoadMatchedCandidates
	| SetMatchedCandidates
	| ClearBrandInterestedCandidates
	| LoadBrandInterestedCandidates
	| SetBrandInterestedCandidates
	| ClearCandidateInterestedCandidates
	| LoadCandidateInterestedCandidates
	| SetCandidateInterestedCandidates
	| ClearSuggestedCandidates
	| LoadSuggestedCandidates
	| SetSuggestedCandidates
	| LoadCandidate
	| SetCandidate
	| ChangeCandidateStateFromCandidateInterestedToMatched
	| ChangeCandidateStateFromCandidateInterestedToBrandRejected
	| ChangeCandidateStateFromSuggestedToBrandInterested
	| ChangeCandidateStateFromSuggestedToBrandRejected
	| AddNoteToCandidate
	| ChangeCandidateState;
