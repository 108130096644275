import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PipelineService {
    constructor(http) {
        this.http = http;
    }
    getPipelineCandidates(roleId, state) {
        return this.http.get(`${environment.baseUrl}/roles/${roleId}/pipelineV2?pipelineState=${state}`);
    }
    moveCandidateToState(roleId, candidateId, state) {
        return this.http.put(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/pipelineState`, { pipelineState: state });
    }
}
PipelineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PipelineService_Factory() { return new PipelineService(i0.ɵɵinject(i1.HttpClient)); }, token: PipelineService, providedIn: "root" });
