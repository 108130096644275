import {CandidateActions, CandidateActionTypes} from '@caroo/candidates/actions/candidate.actions';
import {CandidateStateCounts} from '@caroo/candidates/services/candidate.service';
import {Candidate} from '@caroo/shared/models';

export interface CandidateState {
	candidateStateCounts: { [s: string]: CandidateStateCounts };
	matchedCandidates: { [s: string]: Candidate[] };
	brandInterestedCandidates: { [s: string]: Candidate[] };
	candidateInterestedCandidates: { [s: string]: Candidate[] };
	suggestedCandidates: { [s: string]: Candidate[] };
	candidates: { [s: string]: Candidate };
}

export const initialState: CandidateState = {
	candidateStateCounts: {},
	matchedCandidates: {},
	brandInterestedCandidates: {},
	candidateInterestedCandidates: {},
	suggestedCandidates: {},
	candidates: {}
};

export function reducer(state = initialState, action: CandidateActions): CandidateState {
	let matchedCandidates: { [s: string]: Candidate[] } = {};
	let brandInterestedCandidates: { [s: string]: Candidate[] } = {};
	let candidateInterestedCandidates: { [s: string]: Candidate[] } = {};
	let suggestedCandidates: { [s: string]: Candidate[] } = {};
	switch (action.type) {
		case CandidateActionTypes.SetCandidateStateCounts:
			return {
				...state,
				candidateStateCounts: {
					...state.candidateStateCounts,
					[action.payload.roleId]: action.payload.candidateStateCounts
				}
			};
		case CandidateActionTypes.ClearMatchedCandidates:
			matchedCandidates = {...state.matchedCandidates, [action.payload.roleId]: []};
			return {...state, matchedCandidates};
		case CandidateActionTypes.SetMatchedCandidates:
			matchedCandidates = {
				...state.matchedCandidates,
				[action.payload.roleId]: [
					...state.matchedCandidates[action.payload.roleId] || [],
					...action.payload.candidates.filter(candidate =>
						!(state.matchedCandidates[action.payload.roleId] || []).find(c => c.id === candidate.id)
					)
				]
			};
			return {...state, matchedCandidates};
		case CandidateActionTypes.ClearBrandInterestedCandidates:
			brandInterestedCandidates = {...state.brandInterestedCandidates, [action.payload.roleId]: []};
			return {...state, brandInterestedCandidates};
		case CandidateActionTypes.SetBrandInterestedCandidates:
			brandInterestedCandidates = {
				...state.brandInterestedCandidates,
				[action.payload.roleId]: [
					...state.brandInterestedCandidates[action.payload.roleId] || [],
					...action.payload.candidates.filter(candidate =>
						!(state.brandInterestedCandidates[action.payload.roleId] || []).find(c => c.id === candidate.id)
					)
				]
			};
			return {...state, brandInterestedCandidates};
		case CandidateActionTypes.ClearCandidateInterestedCandidates:
			candidateInterestedCandidates = {...state.candidateInterestedCandidates, [action.payload.roleId]: []};
			return {...state, candidateInterestedCandidates};
		case CandidateActionTypes.SetCandidateInterestedCandidates:
			candidateInterestedCandidates = {
				...state.candidateInterestedCandidates,
				[action.payload.roleId]: [
					...state.candidateInterestedCandidates[action.payload.roleId] || [],
					...action.payload.candidates.filter(candidate =>
						!(state.candidateInterestedCandidates[action.payload.roleId] || []).find(c => c.id === candidate.id)
					)
				]
			};
			return {...state, candidateInterestedCandidates};
		case CandidateActionTypes.ClearSuggestedCandidates:
			suggestedCandidates = {...state.suggestedCandidates, [action.payload.roleId]: []};
			return {...state, suggestedCandidates};
		case CandidateActionTypes.SetSuggestedCandidates:
			suggestedCandidates = {
				...state.suggestedCandidates,
				[action.payload.roleId]: [
					...state.suggestedCandidates[action.payload.roleId] || [],
					...action.payload.candidates.filter(candidate =>
						!(state.suggestedCandidates[action.payload.roleId] || []).find(c => c.id === candidate.id)
					)
				]
			};
			return {...state, suggestedCandidates};
		case CandidateActionTypes.SetCandidate:
			return {
				...state,
				candidates: {
					...state.candidates,
					[`${action.payload.roleId}-${action.payload.candidate.id}`]: action.payload.candidate
				}
			};
		case CandidateActionTypes.ChangeCandidateStateFromCandidateInterestedToMatched:
		case CandidateActionTypes.ChangeCandidateStateFromCandidateInterestedToBrandRejected:
			return {
				...state,
				candidateInterestedCandidates: {
					...state.candidateInterestedCandidates,
					[action.payload.roleId]: (state.candidateInterestedCandidates[action.payload.roleId] || [])
						.filter(c => c.id !== action.payload.candidate.id)
				}
			};
		case CandidateActionTypes.ChangeCandidateStateFromSuggestedToBrandInterested:
		case CandidateActionTypes.ChangeCandidateStateFromSuggestedToBrandRejected:
			return {
				...state,
				suggestedCandidates: {
					...state.suggestedCandidates,
					[action.payload.roleId]: (state.suggestedCandidates[action.payload.roleId] || [])
						.filter(c => c.id !== action.payload.candidate.id)
				}
			};
		default:
			return state;
	}
}
