import { Noop } from './../../store/actions/app.actions';
import { PipelineService } from './../../pipeline/services/pipeline.service';
import {Injectable} from '@angular/core';
import {
	CandidateActions,
	CandidateActionTypes,
	LoadCandidate,
	LoadCandidateStateCounts,
	SetBrandInterestedCandidates,
	SetCandidate,
	SetCandidateInterestedCandidates,
	SetCandidateStateCounts,
	SetMatchedCandidates,
	SetSuggestedCandidates
} from '@caroo/candidates/actions/candidate.actions';
import {CandidateService} from '@caroo/candidates/services/candidate.service';
import {UpdateChatScore} from '@caroo/messages/actions/message.actions';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of, zip} from 'rxjs';
import {map, switchMap, throttleTime} from 'rxjs/operators';

@Injectable()
export class CandidateEffects {

	@Effect()
	loadCandidateStateCounts$ = this.actions$
		.pipe(ofType(CandidateActionTypes.LoadCandidateStateCounts))
		.pipe(throttleTime(1000))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.candidateService.getCandidateStateCounts(action.payload.roleId)))
		)
		.pipe(map(([roleId, candidateStateCounts]) => new SetCandidateStateCounts({roleId, candidateStateCounts})));

	@Effect()
	loadMatchedCandidates$ = this.actions$
		.pipe(ofType(CandidateActionTypes.LoadMatchedCandidates))
		.pipe(throttleTime(1000))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.candidateService.getMatchedCandidates(action.payload.roleId, action.payload.offset, action.payload.limit)))
		)
		.pipe(map(([roleId, candidates]) => new SetMatchedCandidates({roleId, candidates})));

	@Effect()
	loadBrandInterestedCandidates$ = this.actions$
		.pipe(ofType(CandidateActionTypes.LoadBrandInterestedCandidates))
		.pipe(throttleTime(1000))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.candidateService.getBrandInterestedCandidates(action.payload.roleId, action.payload.offset, action.payload.limit)))
		)
		.pipe(map(([roleId, candidates]) => new SetBrandInterestedCandidates({roleId, candidates})));

	@Effect()
	loadCandidateInterestedCandidates$ = this.actions$
		.pipe(ofType(CandidateActionTypes.LoadCandidateInterestedCandidates))
		.pipe(throttleTime(1000))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.candidateService.getCandidateInterestedCandidates(action.payload.roleId, action.payload.offset, action.payload.limit)))
		)
		.pipe(map(([roleId, candidates]) => new SetCandidateInterestedCandidates({roleId, candidates})));

	@Effect()
	loadSuggestedCandidates$ = this.actions$
		.pipe(ofType(CandidateActionTypes.LoadSuggestedCandidates))
		.pipe(throttleTime(1000))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId),
			this.candidateService.getSuggestedCandidates(action.payload.roleId, action.payload.offset, action.payload.limit)))
		)
		.pipe(map(([roleId, candidates]) => new SetSuggestedCandidates({roleId, candidates})));

	@Effect()
	expressInterestInCandidate$ = this.actions$
		.pipe(ofType(
			CandidateActionTypes.ChangeCandidateStateFromCandidateInterestedToMatched,
			CandidateActionTypes.ChangeCandidateStateFromSuggestedToBrandInterested
		))
		.pipe(switchMap(action => zip(
			of(action),
			this.candidateService.expressInterestInCandidate(action.payload.roleId, action.payload.candidate.id)
		)))
		.pipe(switchMap(([{payload}]) => [
			new LoadCandidate({roleId: payload.roleId, candidateId: payload.candidate.id}),
			new LoadCandidateStateCounts({roleId: payload.roleId})
		]));

	@Effect()
	rejectCandidate$ = this.actions$
		.pipe(ofType(
			CandidateActionTypes.ChangeCandidateStateFromCandidateInterestedToBrandRejected,
			CandidateActionTypes.ChangeCandidateStateFromSuggestedToBrandRejected
		))
		.pipe(switchMap(action => zip(
			of(action),
			this.candidateService.rejectCandidate(action.payload.roleId, action.payload.candidate.id),
			this.pipelineService.moveCandidateToState(action.payload.roleId, action.payload.candidate.id, "REJECTED"),
		)))
		.pipe(switchMap(([{payload}]) => [
			new LoadCandidate({roleId: payload.roleId, candidateId: payload.candidate.id}),
			new LoadCandidateStateCounts({roleId: payload.roleId})
		]));

	@Effect()
	loadCandidate$ = this.actions$
		.pipe(ofType(CandidateActionTypes.LoadCandidate))
		.pipe(switchMap(action => zip(
			of(action.payload.roleId), this.candidateService.getCandidate(action.payload.roleId, action.payload.candidateId))
		))
		.pipe(switchMap(([roleId, candidate]) => [
			new SetCandidate({roleId, candidate}),
			new UpdateChatScore({roleId, candidateId: candidate.id, score: candidate.percentageMatch})
		]));

	@Effect()
	addNoteToCandidate$ = this.actions$
		.pipe(ofType(CandidateActionTypes.AddNoteToCandidate))
		.pipe(switchMap(action => zip(
			of(action), this.candidateService.addNoteToCandidate(action.payload.roleId, action.payload.candidateId, action.payload.text))
		))
		.pipe(map(([action]) => new LoadCandidate({roleId: action.payload.roleId, candidateId: action.payload.candidateId})));

	@Effect()
	changeCandidateState$ = this.actions$
		.pipe(ofType(CandidateActionTypes.ChangeCandidateState))
		.pipe(throttleTime(1000))
		.pipe(switchMap(action => zip(
			of(action), this.pipelineService.moveCandidateToState(action.payload.roleId, action.payload.candidateId, action.payload.state))
		))
		.pipe(map(([action]) => new Noop()));

	// tslint:disable-next-line: max-line-length
	constructor(private actions$: Actions<CandidateActions>, private candidateService: CandidateService, private pipelineService: PipelineService) {
	}
}
