var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { RoleActionTypes, SetAllRoleTitles, SetLiveRoles, SetRole, SetRoleTitles, SetSavedRoles, SetScheduledRoles } from '../actions/role.actions';
import { RoleService } from '../services/role.service';
export class RoleEffects {
    constructor(store$, actions$, roleService) {
        this.store$ = store$;
        this.actions$ = actions$;
        this.roleService = roleService;
        this.loadLiveRoles$ = this.actions$
            .pipe(ofType(RoleActionTypes.LoadLiveRoles, RoleActionTypes.SetRolesScope))
            .pipe(withLatestFrom(this.store$))
            .pipe(switchMap(([, state]) => this.roleService.getRoles(state.roles.rolesScope, 'live')))
            .pipe(map(roles => new SetLiveRoles({ roles })));
        this.loadSavedRoles$ = this.actions$
            .pipe(ofType(RoleActionTypes.LoadSavedRoles, RoleActionTypes.SetRolesScope))
            .pipe(withLatestFrom(this.store$))
            .pipe(switchMap(([, state]) => this.roleService.getRoles(state.roles.rolesScope, 'saved')))
            .pipe(map(roles => new SetSavedRoles({ roles })));
        this.loadScheduledRoles$ = this.actions$
            .pipe(ofType(RoleActionTypes.LoadScheduledRoles, RoleActionTypes.SetRolesScope))
            .pipe(withLatestFrom(this.store$))
            .pipe(switchMap(([, state]) => this.roleService.getRoles(state.roles.rolesScope, 'scheduled')))
            .pipe(map(roles => new SetScheduledRoles({ roles })));
        this.loadRoleTitles$ = this.actions$
            .pipe(ofType(RoleActionTypes.LoadRoleTitles))
            .pipe(switchMap(() => this.roleService.getRoleTitles()))
            .pipe(map(roleTitles => new SetRoleTitles({ roleTitles })));
        this.loadAllRoleTitles$ = this.actions$
            .pipe(ofType(RoleActionTypes.LoadAllRoleTitles))
            .pipe(switchMap(() => this.roleService.getAllRoleTitles()))
            .pipe(map(allRoleTitles => new SetAllRoleTitles({ allRoleTitles })));
        this.loadRole$ = this.actions$
            .pipe(ofType(RoleActionTypes.LoadRole))
            .pipe(throttleTime(1000))
            .pipe(switchMap(action => this.roleService.getRole(action.payload.id)))
            .pipe(map(role => new SetRole({ role })));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], RoleEffects.prototype, "loadLiveRoles$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], RoleEffects.prototype, "loadSavedRoles$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], RoleEffects.prototype, "loadScheduledRoles$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], RoleEffects.prototype, "loadRoleTitles$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], RoleEffects.prototype, "loadAllRoleTitles$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], RoleEffects.prototype, "loadRole$", void 0);
