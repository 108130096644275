import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {CandidateEffects} from './effects/candidate.effects';
import {reducer as candidateReducer} from './reducers/candidate.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('candidates', candidateReducer),
		EffectsModule.forFeature([CandidateEffects]),
	]
})
export class CandidateStateModule {
}
