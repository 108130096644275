import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any,
};



export type AcceptBrandInviteInput = {
  token: Scalars['String'],
};

export type AcceptBrandInvitePayload = {
   __typename?: 'AcceptBrandInvitePayload',
  employee: Employee,
};

export type Brand = {
   __typename?: 'Brand',
  culture?: Maybe<RichText>,
  header?: Maybe<BrandHeader>,
  headerVideo?: Maybe<BrandHeaderVideo>,
  id: Scalars['Int'],
  interests: Array<Interest>,
  logo?: Maybe<ImageResource>,
  media: Array<BrandMedia>,
  name: Scalars['String'],
  perks: Array<Perk>,
  story?: Maybe<RichText>,
  tagLine?: Maybe<Scalars['String']>,
  transportAndAmenities?: Maybe<RichText>,
  type?: Maybe<Scalars['String']>,
};

export type BrandHeader = ColourResource | ImageResource;

export type BrandHeaderVideo = VideoResource | YoutubeResource;

export type BrandMedia = ImageResource | VideoResource;

export type Candidate = ConversationParticipant & {
   __typename?: 'Candidate',
  avatar?: Maybe<ImageResource>,
  id: Scalars['Int'],
  isMe: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
};

export type CandidateReach = {
   __typename?: 'CandidateReach',
  industryExperiences: Array<IndustryExperienceCandidateReach>,
  location?: Maybe<LocationCandidateReach>,
  skills: Array<SkillCandidateReach>,
  totalReach: Scalars['Int'],
};

export type CandidateReachInput = {
  industryExperienceIds: Array<Scalars['Int']>,
  location?: Maybe<CandidateReachLocationInput>,
  skillIds: Array<Scalars['Int']>,
};

export type CandidateReachLocationInput = {
  includeRemoteWorkers: Scalars['Boolean'],
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  maxDistance: Scalars['Float'],
};

export type ColleagueInvite = {
   __typename?: 'ColleagueInvite',
  email: Scalars['String'],
  id: Scalars['Int'],
};

export type ColourResource = Resource & {
   __typename?: 'ColourResource',
  colour: Scalars['String'],
  id: Scalars['Int'],
};

export type Content = {
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  publishedAt?: Maybe<Scalars['DateTime']>,
};

export type Conversation = {
   __typename?: 'Conversation',
  candidate: Candidate,
  employee: Employee,
  id: Scalars['Int'],
  isRead: Scalars['Boolean'],
  isUnread: Scalars['Boolean'],
  latestMessage: Message,
  messages: Array<Message>,
};


export type ConversationMessagesArgs = {
  paging: MessagesPagingInput
};

export type ConversationInput = {
  conversationId: Scalars['Int'],
};

export type ConversationParticipant = {
  avatar?: Maybe<ImageResource>,
  id: Scalars['Int'],
  isMe: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
};

export type ConversationsPagingInput = {
  skip: Scalars['Int'],
  take: Scalars['Int'],
};

export type CreatePostInput = {
  text: Scalars['String'],
};

export type CreatePostPayload = {
   __typename?: 'CreatePostPayload',
  post: Post,
};

export type CreateRoleInput = {
  briefDescription?: Maybe<Scalars['String']>,
  employmentTerm: EmploymentTerm,
  experienceLevel?: Maybe<ExperienceLevel>,
  fullDescription?: Maybe<Scalars['String']>,
  industryExperiences: Array<IndustryExperienceInput>,
  isRemoteWorkAllowed: Scalars['Boolean'],
  locationId: Scalars['Int'],
  professionId: Scalars['Int'],
  requirementsAndResponsibilities?: Maybe<Scalars['String']>,
  salary?: Maybe<SalaryInput>,
  skills: Array<SkillInput>,
  title: Scalars['String'],
  youtubeVideoDescription?: Maybe<Scalars['String']>,
};

export type CreateRolePayload = {
   __typename?: 'CreateRolePayload',
  role: Role,
};


export type Employee = ConversationParticipant & {
   __typename?: 'Employee',
  avatar?: Maybe<ImageResource>,
  email: Scalars['String'],
  id: Scalars['Int'],
  isMe: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
};

export enum EmploymentTerm {
  CONTRACT = 'CONTRACT',
  FREELANCE = 'FREELANCE',
  FULL_TIME = 'FULL_TIME',
  OTHER = 'OTHER',
  PART_TIME = 'PART_TIME',
  TEMPORARY = 'TEMPORARY'
}

export enum ExperienceLevel {
  JUNIOR = 'JUNIOR',
  MID_WEIGHT = 'MID_WEIGHT',
  SENIOR = 'SENIOR'
}

export type ImageResource = RemoteFileResource & Resource & {
   __typename?: 'ImageResource',
  id: Scalars['Int'],
  url: Scalars['String'],
};

export type IndustryExperience = {
   __typename?: 'IndustryExperience',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type IndustryExperienceCandidateReach = {
   __typename?: 'IndustryExperienceCandidateReach',
  industryExperience: IndustryExperience,
  reach: Scalars['Int'],
};

export type IndustryExperienceInput = {
  id: Scalars['Int'],
  required: Scalars['Boolean'],
};

export type Interest = {
   __typename?: 'Interest',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type InviteColleagueInput = {
  email: Scalars['String'],
};

export type InviteColleaguePayload = {
   __typename?: 'InviteColleaguePayload',
  colleagueInvite: ColleagueInvite,
  colleagueInvites: Array<ColleagueInvite>,
};

export type LocationCandidateReach = {
   __typename?: 'LocationCandidateReach',
  reach: Scalars['Int'],
};

export type Message = {
   __typename?: 'Message',
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  message: Scalars['String'],
  participant: ConversationParticipant,
};

export type MessagesPagingInput = {
  skip: Scalars['Int'],
  take: Scalars['Int'],
};

export type Mutation = {
   __typename?: 'Mutation',
  acceptBrandInvite: AcceptBrandInvitePayload,
  createPost: CreatePostPayload,
  createRole: CreateRolePayload,
  inviteColleague: InviteColleaguePayload,
  removeRole: RemoveRolePayload,
};


export type MutationAcceptBrandInviteArgs = {
  input: AcceptBrandInviteInput
};


export type MutationCreatePostArgs = {
  input: CreatePostInput
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput
};


export type MutationInviteColleagueArgs = {
  input: InviteColleagueInput
};


export type MutationRemoveRoleArgs = {
  input: RemoveRoleInput
};

export type PagingInput = {
  skip: Scalars['Int'],
  take: Scalars['Int'],
};

export type Perk = {
   __typename?: 'Perk',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type Post = Content & {
   __typename?: 'Post',
  createdAt: Scalars['DateTime'],
  id: Scalars['Int'],
  publishedAt?: Maybe<Scalars['DateTime']>,
  text: Scalars['String'],
};

export type Profession = {
   __typename?: 'Profession',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type Query = {
   __typename?: 'Query',
  brand: Brand,
  candidateReach: CandidateReach,
  colleagueInvites: Array<ColleagueInvite>,
  colleagues: Array<Employee>,
  conversation: Conversation,
  conversations: Array<Conversation>,
  industryExperiences: Array<IndustryExperience>,
  interests: Array<Interest>,
  numCandidates: Scalars['Int'],
  role: Role,
  searchProfessions: Array<Profession>,
  searchSkills: Array<Skill>,
  suggestSkills: Array<Skill>,
};


export type QueryCandidateReachArgs = {
  input: CandidateReachInput
};


export type QueryConversationArgs = {
  input: ConversationInput
};


export type QueryConversationsArgs = {
  paging: ConversationsPagingInput
};


export type QueryRoleArgs = {
  input: RoleInput
};


export type QuerySearchProfessionsArgs = {
  input: SearchProfessionsInput
};


export type QuerySearchSkillsArgs = {
  input: SearchSkillsInput
};


export type QuerySuggestSkillsArgs = {
  input: SuggestSkillsInput
};

export type RemoteFileResource = {
  id: Scalars['Int'],
  url: Scalars['String'],
};

export type RemoveRoleInput = {
  removalReason: RoleRemovalReason,
  roleId: Scalars['Int'],
};

export type RemoveRolePayload = {
   __typename?: 'RemoveRolePayload',
  brand: Brand,
};

export type Resource = {
  id: Scalars['Int'],
};

export type RichText = {
   __typename?: 'RichText',
  html: Scalars['String'],
  markdown: Scalars['String'],
};

export type Role = {
   __typename?: 'Role',
  briefDescription: Scalars['String'],
  employmentTerm: EmploymentTerm,
  experienceLevel?: Maybe<ExperienceLevel>,
  fullDescription?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  isRemoteWorkAllowed: Scalars['Boolean'],
  requirementsAndResponsibilities?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  stats?: Maybe<RoleStats>,
};

export type RoleInput = {
  roleId: Scalars['Int'],
};

export enum RoleRemovalReason {
  CAROO = 'CAROO',
  EXTERNAL = 'EXTERNAL',
  UNNEEDED = 'UNNEEDED'
}

export type RoleStats = {
   __typename?: 'RoleStats',
  numSuggested: Scalars['Int'],
  numInterested: Scalars['Int'],
  numInConversation: Scalars['Int'],
  numInterviews: Scalars['Int'],
  numOfferMade: Scalars['Int'],
  numArchived: Scalars['Int'],
  numVerified: Scalars['Int'],
};

export type SalaryInput = {
  alternateSalaryText?: Maybe<Scalars['String']>,
  max: Scalars['Int'],
  min: Scalars['Int'],
};

export type SearchProfessionsInput = {
  query: Scalars['String'],
};

export type SearchSkillsInput = {
  query: Scalars['String'],
};

export type SingleRoleInput = {
  roleId: Scalars['Int'],
};

export type Skill = {
   __typename?: 'Skill',
  id: Scalars['Int'],
  name: Scalars['String'],
};

export type SkillCandidateReach = {
   __typename?: 'SkillCandidateReach',
  reach: Scalars['Int'],
  skill: Skill,
};

export type SkillInput = {
  id: Scalars['Int'],
  required: Scalars['Boolean'],
};

export type SuggestSkillsInput = {
  professionId: Scalars['Int'],
};

export type VideoResource = RemoteFileResource & Resource & {
   __typename?: 'VideoResource',
  id: Scalars['Int'],
  url: Scalars['String'],
};

export type YoutubeResource = Resource & {
   __typename?: 'YoutubeResource',
  id: Scalars['Int'],
  videoId: Scalars['String'],
};

export type AcceptBrandInviteMutationVariables = {
  input: AcceptBrandInviteInput
};


export type AcceptBrandInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptBrandInvite: (
    { __typename?: 'AcceptBrandInvitePayload' }
    & { employee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
    ) }
  ) }
);

export type CandidateReachDataFragment = (
  { __typename?: 'Query' }
  & Pick<Query, 'numCandidates'>
  & { candidateReach: (
    { __typename?: 'CandidateReach' }
    & Pick<CandidateReach, 'totalReach'>
    & { skills: Array<(
      { __typename?: 'SkillCandidateReach' }
      & Pick<SkillCandidateReach, 'reach'>
      & { skill: (
        { __typename?: 'Skill' }
        & Pick<Skill, 'id' | 'name'>
      ) }
    )>, industryExperiences: Array<(
      { __typename?: 'IndustryExperienceCandidateReach' }
      & Pick<IndustryExperienceCandidateReach, 'reach'>
      & { industryExperience: (
        { __typename?: 'IndustryExperience' }
        & Pick<IndustryExperience, 'id' | 'name'>
      ) }
    )>, location: Maybe<(
      { __typename?: 'LocationCandidateReach' }
      & Pick<LocationCandidateReach, 'reach'>
    )> }
  ) }
);

export type GetCandidateReachQueryVariables = {
  skillIds: Array<Scalars['Int']>,
  industryExperienceIds: Array<Scalars['Int']>,
  location?: Maybe<CandidateReachLocationInput>
};


export type GetCandidateReachQuery = (
  { __typename?: 'Query' }
  & CandidateReachDataFragment
);

export type GetProfileQueryVariables = {};


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { colleagues: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'email'>
    & { avatar: Maybe<(
      { __typename?: 'ImageResource' }
      & Pick<ImageResource, 'id' | 'url'>
    )> }
  )>, colleagueInvites: Array<(
    { __typename?: 'ColleagueInvite' }
    & Pick<ColleagueInvite, 'email'>
  )> }
);

export type InviteColleagueMutationVariables = {
  input: InviteColleagueInput
};


export type InviteColleagueMutation = (
  { __typename?: 'Mutation' }
  & { inviteColleague: (
    { __typename?: 'InviteColleaguePayload' }
    & { colleagueInvites: Array<(
      { __typename?: 'ColleagueInvite' }
      & Pick<ColleagueInvite, 'email'>
    )> }
  ) }
);

export type RemoveRoleMutationVariables = {
  roleId: Scalars['Int'],
  removalReason: RoleRemovalReason
};


export type RemoveRoleMutation = (
  { __typename?: 'Mutation' }
  & { removeRole: (
    { __typename?: 'RemoveRolePayload' }
    & { brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'id'>
    ) }
  ) }
);

export type RoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id'>
  & { stats: Maybe<(
    { __typename?: 'RoleStats' }
    & Pick<RoleStats, 'numSuggested' | 'numInterested' | 'numInConversation' | 'numInterviews' | 'numOfferMade' | 'numArchived' | 'numVerified'>
  )> }
);

export type GetRoleQueryVariables = {
  input: RoleInput
};


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & { role: (
    { __typename?: 'Role' }
    & RoleFragment
  ) }
);

export type ProfessionFragment = (
  { __typename?: 'Profession' }
  & Pick<Profession, 'id' | 'name'>
);

export type SearchProfessionsQueryVariables = {
  query: Scalars['String']
};


export type SearchProfessionsQuery = (
  { __typename?: 'Query' }
  & { searchProfessions: Array<(
    { __typename?: 'Profession' }
    & ProfessionFragment
  )> }
);

export type SkillFragment = (
  { __typename?: 'Skill' }
  & Pick<Skill, 'id' | 'name'>
);

export type SuggestSkillsQueryVariables = {
  professionId: Scalars['Int']
};


export type SuggestSkillsQuery = (
  { __typename?: 'Query' }
  & { suggestSkills: Array<(
    { __typename?: 'Skill' }
    & SkillFragment
  )> }
);

export type SearchSkillsQueryVariables = {
  query: Scalars['String']
};


export type SearchSkillsQuery = (
  { __typename?: 'Query' }
  & { searchSkills: Array<(
    { __typename?: 'Skill' }
    & SkillFragment
  )> }
);

export const CandidateReachDataFragmentDoc = gql`
    fragment CandidateReachData on Query {
  numCandidates
  candidateReach(input: {skillIds: $skillIds, industryExperienceIds: $industryExperienceIds, location: $location}) {
    skills {
      skill {
        id
        name
      }
      reach
    }
    industryExperiences {
      industryExperience {
        id
        name
      }
      reach
    }
    location {
      reach
    }
    totalReach
  }
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on Role {
  id
  stats {
    numSuggested
    numInterested
    numInConversation
    numInterviews
    numOfferMade
    numArchived
    numVerified
  }
}
    `;
export const ProfessionFragmentDoc = gql`
    fragment Profession on Profession {
  id
  name
}
    `;
export const SkillFragmentDoc = gql`
    fragment Skill on Skill {
  id
  name
}
    `;
export const AcceptBrandInviteDocument = gql`
    mutation AcceptBrandInvite($input: AcceptBrandInviteInput!) {
  acceptBrandInvite(input: $input) {
    employee {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptBrandInviteGQL extends Apollo.Mutation<AcceptBrandInviteMutation, AcceptBrandInviteMutationVariables> {
    document = AcceptBrandInviteDocument;
    
  }
export const GetCandidateReachDocument = gql`
    query GetCandidateReach($skillIds: [Int!]!, $industryExperienceIds: [Int!]!, $location: CandidateReachLocationInput) {
  ...CandidateReachData
}
    ${CandidateReachDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCandidateReachGQL extends Apollo.Query<GetCandidateReachQuery, GetCandidateReachQueryVariables> {
    document = GetCandidateReachDocument;
    
  }
export const GetProfileDocument = gql`
    query GetProfile {
  colleagues {
    id
    name
    email
    avatar {
      id
      url
    }
  }
  colleagueInvites {
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProfileGQL extends Apollo.Query<GetProfileQuery, GetProfileQueryVariables> {
    document = GetProfileDocument;
    
  }
export const InviteColleagueDocument = gql`
    mutation InviteColleague($input: InviteColleagueInput!) {
  inviteColleague(input: $input) {
    colleagueInvites {
      email
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteColleagueGQL extends Apollo.Mutation<InviteColleagueMutation, InviteColleagueMutationVariables> {
    document = InviteColleagueDocument;
    
  }
export const RemoveRoleDocument = gql`
    mutation RemoveRole($roleId: Int!, $removalReason: RoleRemovalReason!) {
  removeRole(input: {roleId: $roleId, removalReason: $removalReason}) {
    brand {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveRoleGQL extends Apollo.Mutation<RemoveRoleMutation, RemoveRoleMutationVariables> {
    document = RemoveRoleDocument;
    
  }
export const GetRoleDocument = gql`
    query getRole($input: RoleInput!) {
  role(input: $input) {
    ...Role
  }
}
    ${RoleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRoleGQL extends Apollo.Query<GetRoleQuery, GetRoleQueryVariables> {
    document = GetRoleDocument;
    
  }
export const SearchProfessionsDocument = gql`
    query SearchProfessions($query: String!) {
  searchProfessions(input: {query: $query}) {
    ...Profession
  }
}
    ${ProfessionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProfessionsGQL extends Apollo.Query<SearchProfessionsQuery, SearchProfessionsQueryVariables> {
    document = SearchProfessionsDocument;
    
  }
export const SuggestSkillsDocument = gql`
    query SuggestSkills($professionId: Int!) {
  suggestSkills(input: {professionId: $professionId}) {
    ...Skill
  }
}
    ${SkillFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SuggestSkillsGQL extends Apollo.Query<SuggestSkillsQuery, SuggestSkillsQueryVariables> {
    document = SuggestSkillsDocument;
    
  }
export const SearchSkillsDocument = gql`
    query SearchSkills($query: String!) {
  searchSkills(input: {query: $query}) {
    ...Skill
  }
}
    ${SkillFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchSkillsGQL extends Apollo.Query<SearchSkillsQuery, SearchSkillsQueryVariables> {
    document = SearchSkillsDocument;
    
  }