import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Candidate} from '@caroo/shared/models';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CandidateService {

	constructor(private http: HttpClient) {
	}

	getCandidateStateCounts(roleId: number): Observable<CandidateStateCounts> {
		return this.http.get<CandidateStateCounts>(`${environment.baseUrl}/roles/${roleId}/candidate-state-counts`);
	}

	getMatchedCandidates(roleId: number, offset: number, limit: number): Observable<Candidate[]> {
		return this.http.get<Candidate[]>(`${environment.baseUrl}/roles/${roleId}/candidates/matched?offset=${offset}&limit=${limit}`);
	}

	getBrandInterestedCandidates(roleId: number, offset: number, limit: number): Observable<Candidate[]> {
		return this.http.get<Candidate[]>(`${environment.baseUrl}/roles/${roleId}/candidates/brand-interested?offset=${offset}&limit=${limit}`);
	}

	getCandidateInterestedCandidates(roleId: number, offset: number, limit: number): Observable<Candidate[]> {
		// tslint:disable-next-line: max-line-length
		return this.http.get<Candidate[]>(`${environment.baseUrl}/roles/${roleId}/candidates/candidate-interested?offset=${offset}&limit=${limit}`);
	}

	getSuggestedCandidates(roleId: number, offset: number, limit: number): Observable<Candidate[]> {
		return this.http.get<Candidate[]>(`${environment.baseUrl}/roles/${roleId}/candidates/suggested?offset=${offset}&limit=${limit}`);
	}

	expressInterestInCandidate(roleId: number, candidateId: number) {
		return this.http.put(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/interested`, undefined);
	}

	rejectCandidate(roleId: number, candidateId: number) {
		return this.http.put(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/rejected`, undefined);
	}

	getCandidate(roleId: number, candidateId: number): Observable<Candidate> {
		return this.http.get<Candidate>(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}`);
	}

	addNoteToCandidate(roleId: number, candidateId: number, text: string): Observable<any> {
		return this.http.post(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/notes`, {text});
	}

	shareCandidateWithColleagues(roleId: number, candidateId: number, emails: string[]) {
		return this.http.post<any>(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/share`, {emails});
	}
}

export interface CandidateStateCounts {
	suggested: number;
	candidateFavourited: number;
	candidateInterested: number;
	candidateInterestedBrandRejected: number;
	candidateRejected: number;
	brandInterested: number;
	brandInterestedCandidateRejected: number;
	brandRejected: number;
	matched: number;
}
