import { ExperienceLevel } from "@caroo/shared/models/experience-level";
import { IndustryExperience } from "@caroo/shared/models/industry-experience";
import { Location, Resource, Skill } from "./";

export interface Candidate {
	id: number;
	name: string;
	location: Location | null;
	isOpenToRemoteWork: boolean;
	distance: number | null;
	avatar: Resource;
	video: Resource;
	url: string;
	bio: string;
	experienceLevel: ExperienceLevel;
	percentageMatch: number;
	matchedSkills: string[];
	matchedInterests: string[];
	matchedIndustryExperiences: string[];
	skills: string[];
	industryExperiences: IndustryExperience[];
	interests: string[];
	experience: CareerHighlight[];
	education: EducationHighlight[];
	personalHighlights: PersonalHighlight[];
	highlights: Highlight[];
	state: State;
	pipelineState: string;
	secondaryState: string;
	following: boolean;
	applied: boolean;
	roleId: number;
	chatId: number;
	notes: Note[];
	activity: Activity[];
	lastActive: string;
	previousCandidateId: number | null;
	nextCandidateId: number | null;
	status?: Status | null;
	link?: Link;
	cv?: Resource;
	cvFileName?: string;
}

export enum HighlightType {
	CAREER = "CAREER",
	EDUCATION = "EDUCATION",
	PERSONAL = "PERSONAL",
}

export interface CareerHighlight {
	highlightType: HighlightType.CAREER;
	id: number;
	title: string;
	company: string;
	from: string;
	to?: string;
	description?: string;
	media: Resource[];
	skills: Skill[];
	industryExperiences: IndustryExperience[];
}

export interface EducationHighlight {
	highlightType: HighlightType.EDUCATION;
	id: number;
	qualification: string;
	institution: string;
	from: string;
	to?: string;
	description?: string;
	media: Resource[];
	skills: Skill[];
}

export interface PersonalHighlight {
	highlightType: HighlightType.PERSONAL;
	id: number;
	title: string;
	organisation: string;
	date: string;
	description: string;
	media: Resource[];
}

export type Highlight =
	| CareerHighlight
	| EducationHighlight
	| PersonalHighlight;

export enum State {
	SUGGESTED = "SUGGESTED",
	CANDIDATE_INTERESTED = "CANDIDATE_INTERESTED",
	BRAND_INTERESTED = "BRAND_INTERESTED",
	BRAND_INTERESTED_CANDIDATE_REJECTED = "BRAND_INTERESTED_CANDIDATE_REJECTED",
	MATCHED = "MATCHED",
}

export enum PipelineStateEnum {
	VERIFIED = "VERIFIED",
	MATCHED = "MATCHED",
	INTERVIEW = "INTERVIEW",
	HIRED = "HIRED",
	REJECTED = "REJECTED",
	SAVED_FOR_LATER = "SAVED_FOR_LATER",
}

export enum PipelineState {
	REVIEW = "REVIEW",
	VERIFIED = "VERIFIED",
	INTERVIEW = "INTERVIEW",
	OFFER = "OFFER",
}

export interface Note {
	creator: string;
	text: string;
	createdAt: string;
}

export interface Activity {
	role: string;
	candidate: {
		name: string;
		avatar: Resource;
	};
	brandUser: {
		name: string;
		avatar: Resource;
	};
	type: "CANDIDATE_EXPRESSED_INTEREST" | "MATCHED";
	createdAt: string;
}

export enum Status {
	ACTIVE = "ACTIVE",
	OPEN_TO_CHAT = "OPEN_TO_CHAT",
	DO_NOT_DISTURB = "DO_NOT_DISTURB",
}

export interface Link {
	id: number;
	roleId: number;
	candidateExpressedInterestAt?: string;
	candidateNotifiedAt?: string;
	brandExpressedInterestAt?: string;
	updatedAt?: string;
}
