import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { PaymentStateModule } from "@caroo/payment/payment-state.module";
import { RoleStateModule } from "@caroo/roles/role-state.module";
import { NgLetModule } from "@ngrx-utils/store";
import { ActionCardComponent } from "./action-card.component";

@NgModule({
    declarations: [ActionCardComponent],
    imports: [
        CommonModule,
        MatIconModule,
        RouterModule,
        RoleStateModule,
        PaymentStateModule,
        NgLetModule,
    ],
    exports: [ActionCardComponent],
})
export class ActionCardModule {
}