import {Action} from '@ngrx/store';
import {Role} from '../../shared/models';

export enum RoleActionTypes {
	SetRolesScope = '[Role] Set Roles Scope',
	LoadLiveRoles = '[Role] Load Live Roles',
	SetLiveRoles = '[Role] Set Live Roles',
	LoadSavedRoles = '[Role] Load Saved Roles',
	SetSavedRoles = '[Role] Set Saved Roles',
	LoadScheduledRoles = '[Role] Load Scheduled Roles',
	SetScheduledRoles = '[Role] Set Scheduled Roles',
	LoadRoleTitles = '[Role] Load Role Titles',
	SetRoleTitles = '[Role] Set Role Titles',
	LoadAllRoleTitles = '[Role] Load All Role Titles',
	SetAllRoleTitles = '[Role] Set All Role Titles',
	LoadRole = '[Role] Load Role',
	SetRole = '[Role] Set Role',
	RemoveRole = '[Role] Remove Role',
	SetAddedRoleId = '[Role] Set Added Role ID',
	SetRolesWithActions = '[Role] Set roles with actions'
}

export class SetRolesScope implements Action {
	readonly type = RoleActionTypes.SetRolesScope;

	constructor(public payload: { rolesScope: 'all' | 'own' }) {
	}
}

export class LoadLiveRoles implements Action {
	readonly type = RoleActionTypes.LoadLiveRoles;
}

export class SetLiveRoles implements Action {
	readonly type = RoleActionTypes.SetLiveRoles;

	constructor(public payload: { roles: Role[] }) {
	}
}

export class LoadSavedRoles implements Action {
	readonly type = RoleActionTypes.LoadSavedRoles;
}

export class SetSavedRoles implements Action {
	readonly type = RoleActionTypes.SetSavedRoles;

	constructor(public payload: { roles: Role[] }) {
	}
}

export class LoadScheduledRoles implements Action {
	readonly type = RoleActionTypes.LoadScheduledRoles;
}

export class SetScheduledRoles implements Action {
	readonly type = RoleActionTypes.SetScheduledRoles;

	constructor(public payload: { roles: Role[] }) {
	}
}

export class LoadRoleTitles implements Action {
	readonly type = RoleActionTypes.LoadRoleTitles;
}

export class SetRoleTitles implements Action {
	readonly type = RoleActionTypes.SetRoleTitles;

	constructor(public payload: { roleTitles: Role[] }) {
	}
}

export class LoadAllRoleTitles implements Action {
	readonly type = RoleActionTypes.LoadAllRoleTitles;
}

export class SetAllRoleTitles implements Action {
	readonly type = RoleActionTypes.SetAllRoleTitles;

	constructor(public payload: { allRoleTitles: Role[] }) {
	}
}

export class LoadRole implements Action {
	readonly type = RoleActionTypes.LoadRole;

	constructor(public payload: { id: number }) {
	}
}

export class SetRole implements Action {
	readonly type = RoleActionTypes.SetRole;

	constructor(public payload: { role: Role }) {
	}
}

export class RemoveRole implements Action {
	readonly type = RoleActionTypes.RemoveRole;

	constructor(public payload: { id: number }) {
	}
}

export class SetAddedRoleId implements Action {
	readonly type = RoleActionTypes.SetAddedRoleId;

	constructor(public payload: { roleId: number }) {
	}
}

export class SetRolesWithActions implements Action {
	readonly type = RoleActionTypes.SetRolesWithActions;

	constructor(public payload: {roles: Role[]}) {};
}


export type RoleActions =
	| SetRolesScope
	| LoadLiveRoles
	| SetLiveRoles
	| LoadSavedRoles
	| SetSavedRoles
	| LoadScheduledRoles
	| SetScheduledRoles
	| LoadRoleTitles
	| SetRoleTitles
	| LoadAllRoleTitles
	| SetAllRoleTitles
	| LoadRole
	| SetRole
	| SetAddedRoleId
	| SetRolesWithActions;
