import {NgModule} from '@angular/core';
import {PipelineEffects} from '@caroo/pipeline/effects/pipeline.effects';
import {reducer as pipelineReducer} from '@caroo/pipeline/reducers/pipeline.reducer';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

@NgModule({
	imports: [
		StoreModule.forFeature('pipeline', pipelineReducer),
		EffectsModule.forFeature([PipelineEffects]),
	]
})
export class PipelineStateModule {
}
