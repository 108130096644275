import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CandidateService {
    constructor(http) {
        this.http = http;
    }
    getCandidateStateCounts(roleId) {
        return this.http.get(`${environment.baseUrl}/roles/${roleId}/candidate-state-counts`);
    }
    getMatchedCandidates(roleId, offset, limit) {
        return this.http.get(`${environment.baseUrl}/roles/${roleId}/candidates/matched?offset=${offset}&limit=${limit}`);
    }
    getBrandInterestedCandidates(roleId, offset, limit) {
        return this.http.get(`${environment.baseUrl}/roles/${roleId}/candidates/brand-interested?offset=${offset}&limit=${limit}`);
    }
    getCandidateInterestedCandidates(roleId, offset, limit) {
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${environment.baseUrl}/roles/${roleId}/candidates/candidate-interested?offset=${offset}&limit=${limit}`);
    }
    getSuggestedCandidates(roleId, offset, limit) {
        return this.http.get(`${environment.baseUrl}/roles/${roleId}/candidates/suggested?offset=${offset}&limit=${limit}`);
    }
    expressInterestInCandidate(roleId, candidateId) {
        return this.http.put(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/interested`, undefined);
    }
    rejectCandidate(roleId, candidateId) {
        return this.http.put(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/rejected`, undefined);
    }
    getCandidate(roleId, candidateId) {
        return this.http.get(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}`);
    }
    addNoteToCandidate(roleId, candidateId, text) {
        return this.http.post(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/notes`, { text });
    }
    shareCandidateWithColleagues(roleId, candidateId, emails) {
        return this.http.post(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/share`, { emails });
    }
}
CandidateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CandidateService_Factory() { return new CandidateService(i0.ɵɵinject(i1.HttpClient)); }, token: CandidateService, providedIn: "root" });
