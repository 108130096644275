import { Injectable } from "@angular/core";
import { RouterState, selectRouterUrl } from "@caroo/notifications/reducers";
import { MobileQueryService } from "@caroo/services/mobile-query.service";
import { CoachmarkDirective } from "@caroo/shared/coachmarks/coachmark.directive";
import { PopoverDirective } from "@caroo/shared/components/popover";
import { Store } from "@ngrx/store";
import { Subscription, fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class CoachmarkService {
	private coachGroups: {
		[s: string]: {
			coachmark: CoachmarkDirective;
			popover: PopoverDirective;
		}[];
	} = {};

	private top: HTMLElement;
	private right: HTMLElement;
	private bottom: HTMLElement;
	private left: HTMLElement;

	private topLeft: HTMLElement;
	private topRight: HTMLElement;
	private bottomRight: HTMLElement;
	private bottomLeft: HTMLElement;

	private windowSubscription: Subscription;

	constructor(
		private readonly mobileQueryService: MobileQueryService,
		private routerStore: Store<RouterState>
	) {
		this.routerStore
			.select(selectRouterUrl)
			.subscribe(() => this.closeAllCoachmarks());
	}

	registerCoachmark(item: {
		coachmark: CoachmarkDirective;
		popover: PopoverDirective;
	}) {
		if (this.coachGroups[item.coachmark.coachGroupId]) {
			this.coachGroups[item.coachmark.coachGroupId] = [
				...this.coachGroups[item.coachmark.coachGroupId],
				item,
			].sort(sortCoachmarks);
		} else {
			this.coachGroups[item.coachmark.coachGroupId] = [item];
		}
	}

	unregisterCoachmark(item: {
		coachmark: CoachmarkDirective;
		popover: PopoverDirective;
	}) {
		for (const groupId in this.coachGroups) {
			if (this.coachGroups.hasOwnProperty(groupId)) {
				const index = this.coachGroups[groupId].indexOf(item);
				if (index >= 0) {
					this.coachGroups[groupId].splice(index, 1);
				}
			}
		}
	}

	closeAllCoachmarks() {
		for (const group in this.coachGroups) {
			if (this.coachGroups.hasOwnProperty(group)) {
				this.coachGroups[group].forEach((item) =>
					item.popover.closePopover()
				);
			}
		}
		setTimeout(() => {
			this.hideOverlayDivs();
		}, 0);
		this.windowSubscription.unsubscribe()
	}

	private setupBackdropDivs(popover: PopoverDirective) {
		const overlayContainer = document.querySelector(
			".cdk-overlay-container"
		);
		if (!this.top) {
			this.top = document.createElement("div");
			this.top.className = "coachmark-overlay top";
			overlayContainer.appendChild(this.top);
		}
		if (!this.right) {
			this.right = document.createElement("div");
			this.right.className = "coachmark-overlay right";
			overlayContainer.appendChild(this.right);
		}
		if (!this.bottom) {
			this.bottom = document.createElement("div");
			this.bottom.className = "coachmark-overlay bottom";
			overlayContainer.appendChild(this.bottom);
		}
		if (!this.left) {
			this.left = document.createElement("div");
			this.left.className = "coachmark-overlay left";
			overlayContainer.appendChild(this.left);
		}
		if (!this.topLeft) {
			this.topLeft = document.createElement("div");
			this.topLeft.className = "coachmark-overlay corner top-left";
			overlayContainer.appendChild(this.topLeft);
		}
		if (!this.topRight) {
			this.topRight = document.createElement("div");
			this.topRight.className = "coachmark-overlay corner top-right";
			overlayContainer.appendChild(this.topRight);
		}
		if (!this.bottomRight) {
			this.bottomRight = document.createElement("div");
			this.bottomRight.className =
				"coachmark-overlay corner bottom-right";
			overlayContainer.appendChild(this.bottomRight);
		}
		if (!this.bottomLeft) {
			this.bottomLeft = document.createElement("div");
			this.bottomLeft.className = "coachmark-overlay corner bottom-left";
			overlayContainer.appendChild(this.bottomLeft);
		}
		this.top.onclick = () => this.closeCoachmark(popover);
		this.right.onclick = () => this.closeCoachmark(popover);
		this.bottom.onclick = () => this.closeCoachmark(popover);
		this.left.onclick = () => this.closeCoachmark(popover);
	}

	private moveBackdropDivs(element: HTMLElement) {
		const overlayContainer = document.querySelector(
			".cdk-overlay-container"
		);
		const rect = element.getBoundingClientRect();
		this.top.style.height = `${rect.top}px`;
		this.right.style.top = `${rect.top}px`;
		this.right.style.width = `${
			overlayContainer.getBoundingClientRect().width - rect.right
		}px`;
		this.right.style.height = `${rect.height}px`;
		this.bottom.style.height = `${
			overlayContainer.getBoundingClientRect().height - rect.bottom
		}px`;
		this.left.style.top = `${rect.top}px`;
		this.left.style.width = `${rect.left}px`;
		this.left.style.height = `${rect.height}px`;

		this.topLeft.style.top = `${rect.top}px`;
		this.topLeft.style.left = `${rect.left}px`;
		this.topRight.style.top = `${rect.top}px`;
		this.topRight.style.right = `${
			overlayContainer.getBoundingClientRect().width - rect.right
		}px`;
		this.bottomRight.style.right = `${
			overlayContainer.getBoundingClientRect().width - rect.right
		}px`;
		this.bottomRight.style.bottom = `${
			overlayContainer.getBoundingClientRect().height - rect.bottom
		}px`;
		this.bottomLeft.style.bottom = `${
			overlayContainer.getBoundingClientRect().height - rect.bottom
		}px`;
		this.bottomLeft.style.left = `${rect.left}px`;
	}

	private hideOverlayDivs() {
		if (this.top) {
			this.top.style.height = "";
			this.right.style.top = "";
		}
		if (this.right) {
			this.right.style.width = "";
			this.right.style.height = "";
		}
		if (this.bottom) {
			this.bottom.style.height = "";
		}
		if (this.left) {
			this.left.style.top = "";
			this.left.style.width = "";
			this.left.style.height = "";
		}

		if (this.topLeft) {
			this.topLeft.style.top = "";
			this.topLeft.style.left = "";
		}
		if (this.topRight) {
			this.topRight.style.top = "";
			this.topRight.style.right = "";
		}
		if (this.bottomRight) {
			this.bottomRight.style.right = "";
			this.bottomRight.style.bottom = "";
		}
		if (this.bottomLeft) {
			this.bottomLeft.style.bottom = "";
			this.bottomLeft.style.left = "";
		}
	}

	private injectCoachmarkProgress(
		group: { popover: PopoverDirective; coachmark: CoachmarkDirective }[],
		index: number
	) {
		if (group.length > 1) {
			const coachmarkNav = document.createElement("div");
			coachmarkNav.className = "coachmark-nav";

			const coachmarkPrevious = document.createElement("span");
			coachmarkPrevious.textContent = "Back";
			if (index === 0) {
				coachmarkPrevious.style.visibility = "hidden";
			} else {
				coachmarkPrevious.onclick = () => {
					group[index].popover.closePopover();
					this.displayCoachmark(group, index - 1);
				};
			}
			coachmarkNav.appendChild(coachmarkPrevious);

			const coachmarkProgress = document.createElement("div");
			coachmarkProgress.className = "coachmark-progress";
			for (let i = 0; i < group.length; i++) {
				const coachmarkProgressDot = document.createElement("span");
				coachmarkProgressDot.className = "progress";
				if (i === index) {
					coachmarkProgressDot.className += " selected";
				}
				coachmarkProgress.appendChild(coachmarkProgressDot);
			}
			coachmarkNav.appendChild(coachmarkProgress);

			const coachmarkNext = document.createElement("span");
			coachmarkNext.textContent = "Next";
			if (index === group.length - 1) {
				coachmarkNext.style.visibility = "hidden";
			} else {
				coachmarkNext.onclick = () => {
					group[index].popover.closePopover();
					this.displayCoachmark(group, index + 1);
				};
			}
			this.windowSubscription.unsubscribe()
			coachmarkNav.appendChild(coachmarkNext);

			(
				document.querySelector("caroo-popover") as HTMLElement
			).appendChild(coachmarkNav);
		}
	}

	private displayCoachmark(
		group: { popover: PopoverDirective; coachmark: CoachmarkDirective }[],
		index: number
	) {
		const popover = group[index].popover;
		popover.openPopover(false);
		this.injectCoachmarkProgress(group, index);
		this.setupBackdropDivs(popover);
		this.moveBackdropDivs(group[index].coachmark.elementRef.nativeElement);
		this.windowSubscription = fromEvent(window, "resize")
			.pipe(debounceTime(200))
			.subscribe(() => {
				this.moveBackdropDivs(
					group[index].coachmark.elementRef.nativeElement
				);
			});
		document.querySelector("html").classList.add("cdk-global-scrollblock");
	}

	private closeCoachmark(popover: PopoverDirective) {
		popover.closePopover();
		this.hideOverlayDivs();
		document
			.querySelector("html")
			.classList.remove("cdk-global-scrollblock");
		this.windowSubscription.unsubscribe()
	}

	startCoach(groupId: string, alwaysShow: boolean = false): boolean {
		if (!this.mobileQueryService.isMobile) {
			const storageId = `coachmark-displayed:${groupId}`;
			const group = this.coachGroups[groupId];
			if (
				group &&
				group.length > 0 &&
				(alwaysShow || !window.localStorage.getItem(storageId))
			) {
				this.displayCoachmark(group, 0);
				window.localStorage.setItem(storageId, "true");
				return true;
			} else {
				return false;
			}
		}
	}
}

const sortCoachmarks = (
	a: { coachmark: CoachmarkDirective; popover: PopoverDirective },
	b: { coachmark: CoachmarkDirective; popover: PopoverDirective }
) => a.coachmark.coachIndex - b.coachmark.coachIndex;
