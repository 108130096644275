<mat-sidenav-container>
	<mat-sidenav class="nav" position="start" [mode]="mobileQueryService.isMobile ? 'over' : 'side'" [opened]="!mobileQueryService.isMobile && showNav" #sidenav>
		<div class="logo" [carooPopover]="numCandidatesCoachmarkPopover" position="centre" mode="coachmark" coachGroupId="dashboard-initial" coachIndex="0">
			<img src="assets/images/logo.svg" alt="logo"/>
			<caroo-num-candidates *ngIf="showNav"></caroo-num-candidates>
		</div>
		<ng-template #numCandidatesCoachmarkPopover>
			<div class="coachmark-inner">
				<h5>How many candidates are on Caroo?</h5>
				<p>We want to give you complete transparency<br/>on our business and community so.....</p>
				<p>These show the total number of candidate<br/>accounts and how many joined in the last week</p>
			</div>
		</ng-template>
		<nav *ngIf="linkState$ | async as linkState">
			<a mat-ripple matRippleColor="rgba(255, 255, 255, 0.2)" class="subtitle" [class.active]="linkState === LinkState.DASHBOARD" routerLink="/" (click)="closeSidenavIfMobile()">
				<mat-icon class="subtitle" svgIcon="dashboard"></mat-icon>
				Dashboard
			</a>
			<a mat-ripple matRippleColor="rgba(255, 255, 255, 0.2)" class="subtitle" [class.active]="linkState === LinkState.ROLES" routerLink="/roles" (click)="closeSidenavIfMobile()">
				<mat-icon class="subtitle" svgIcon="roles"></mat-icon>
				Roles
			</a>
			<a mat-ripple matRippleColor="rgba(255, 255, 255, 0.2)" class="subtitle" [class.active]="linkState === LinkState.PIPELINE" routerLink="/pipeline" (click)="closeSidenavIfMobile()">
				<mat-icon class="subtitle" svgIcon="pipeline"></mat-icon>
				Pipeline
			</a>
			<a mat-ripple matRippleColor="rgba(255, 255, 255, 0.2)" class="subtitle" [class.active]="linkState === LinkState.CONTENT_HUB" routerLink="/content-hub" (click)="closeSidenavIfMobile()">
				<mat-icon class="subtitle" svgIcon="content-hub"></mat-icon>
				Content
			</a>
			<a mat-ripple matRippleColor="rgba(255, 255, 255, 0.2)" class="subtitle" [class.active]="linkState === LinkState.MESSAGES" routerLink="/messages" (click)="closeSidenavIfMobile()">
				<mat-icon class="subtitle" svgIcon="messages"></mat-icon>
				Messages
			</a>
			<a mat-ripple matRippleColor="rgba(255, 255, 255, 0.2)" class="subtitle" [class.active]="linkState === LinkState.PROFILE" routerLink="/profile" (click)="closeSidenavIfMobile()">
				<mat-icon class="subtitle" svgIcon="profile"></mat-icon>
				Profile
			</a>
		</nav>
		<ng-container *ngIf="userProfile$ | async as userProfile">
			<ng-container *ngIf="profile$ | async as profile">
				<div class="humans">
					<label class="body1"><span class="body2">You are logged in as</span>{{userProfile?.name ? userProfile.name + ', ' : ''}}{{profile?.name}}</label>
					<div class="avatars">
						<caroo-avatar [resource]="userProfile?.avatar"></caroo-avatar>
						<caroo-logo [resource]="(profile$ | async).logo"></caroo-logo>
					</div>
					<button caroo-secondary-button (click)="inviteColleagues()">Invite&nbsp;Colleagues
					</button>
				</div>
			</ng-container>
		</ng-container>
		<a mat-ripple matRippleColor="rgba(255, 255, 255, 0.2)" class="subtitle feedback" href="mailto:employer-feedback@caroo.co.uk?subject=Caroo Employer Portal Feedback">
			<mat-icon class="subtitle">email</mat-icon>
			Send Us Feedback
		</a>
		<label class="body2 copyright">&copy; Caroo 2020</label>
	</mat-sidenav>
	<mat-sidenav class="notifications" position="end" mode="over" #notificationsList>
		<caroo-notification-list *ngIf="showNav" (notificationClicked)="notificationsList.close()"></caroo-notification-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<mat-toolbar *ngIf="showNav">
			<button mat-icon-button (click)="sidenav.toggle()" *ngIf="mobileQueryService.isMobile">
				<mat-icon>menu</mat-icon>
			</button>
			<a class="logo" routerLink="/">
				<img src="assets/images/logo.svg" alt="Logo"/>
			</a>
			<button class="add-role" caroo-add-role-button (click)="goToAddRole()">Add New Role</button>
			<a routerLink="/content-hub/add">
				<button class="add-content" caroo-primary-button [carooPopover]="postContentPopover" position="centre" mode="coachmark" coachGroupId="content-hub-initial" [coachIndex]="2">
					Post Content
				</button>
				<ng-template #postContentPopover>
					<div class="coachmark-inner">
						<p>Ready to go? Post some content now</p>
					</div>
				</ng-template>
			</a>
			<div class="right">
				<div class="humans" *ngIf="userProfile$ | async as userProfile">
					<label class="subtitle">{{userProfile?.name}}</label>
					<div class="avatars">
						<caroo-avatar [resource]="userProfile?.avatar" routerLink="/profile"></caroo-avatar>
						<caroo-logo [resource]="(profile$ | async)?.logo" routerLink="/profile/edit"></caroo-logo>
					</div>
					<button caroo-secondary-button (click)="inviteColleagues()" [carooPopover]="inviteColleaguesCoachmarkPopover" position="left" mode="coachmark" coachGroupId="invite-colleagues"
					        coachIndex="0">Invite Colleagues
					</button>
					<ng-template #inviteColleaguesCoachmarkPopover>
						<div class="coachmark-inner">
							<h5>Invite your colleagues</h5>
							<p>Have you got colleagues looking to fill job vacancies?<br/>If so, you can invite them to Caroo here.<br/>There is no cost for adding more colleagues</p>
						</div>
					</ng-template>
				</div>
				<div class="notifications">
					<button mat-icon-button (click)="notificationsList.toggle()">
						<mat-icon svgIcon="notifications"></mat-icon>
					</button>
					<ng-container *ngIf="numUnreadNotifications$ | async as numUnreadNotifications">
						<label class="body3" *ngIf="numUnreadNotifications > 0">{{numUnreadNotifications}}</label>
					</ng-container>
				</div>
			</div>
			<mat-progress-bar mode="indeterminate" *ngIf="loading | async"></mat-progress-bar>
		</mat-toolbar>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" style="display: none">
	<defs>
		<filter id="blur">
			<feGaussianBlur stdDeviation="5"/>
		</filter>
	</defs>
</svg>
