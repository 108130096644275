import {PipelineActions, PipelineActionTypes} from '@caroo/pipeline/actions/pipeline.actions';
import {Candidate} from '@caroo/shared/models';

export interface PipelineState {
	matches: Candidate[];
	interview: Candidate[];
	hired: Candidate[];
	rejected: Candidate[];
	savedForLater: Candidate[];
	verified: Candidate[];
	pipelineRoleFilterId?: number;
}

export const initialState: PipelineState = {
	matches: [],
	interview: [],
	hired: [],
	rejected: [],
	verified: [],
	savedForLater: []
};

function pipelineStateToValid(actionState) {
	if (actionState === 'MATCHED') {
		return 'matches';
	} else if (actionState === 'SAVED_FOR_LATER') {
		return 'savedForLater';
	} else {
		return actionState.toLowerCase();
	}
}

const states = [
	'MATCHED',
	'INTERVIEW',
	'HIRED',
	'REJECTED',
	'SAVED_FOR_LATER'
];

export function reducer(state = initialState, action: PipelineActions): PipelineState {
	switch (action.type) {
		case PipelineActionTypes.SetVerified:
			return {
				...state,
				verified: action.payload.candidates
			}
		case PipelineActionTypes.SetMatches:
			return {
				...state,
				matches: action.payload.candidates
			};
		case PipelineActionTypes.SetInterview:
			return {
				...state,
				interview: action.payload.candidates
			};
		case PipelineActionTypes.SetHired:
			return {
				...state,
				hired: action.payload.candidates
			};
		case PipelineActionTypes.SetRejected:
			return {
				...state,
				rejected: action.payload.candidates
			};
		case PipelineActionTypes.SetSavedForLater:
			return {
				...state,
				savedForLater: action.payload.candidates
			};
		case PipelineActionTypes.SetPipelineFilterId:
			return {
				...state,
				pipelineRoleFilterId: action.payload.roleId
			};
		case PipelineActionTypes.SetPipelineNote:
			const pipelineState = pipelineStateToValid(action.payload.candidate.pipelineState);
			return {
				...state,
				[pipelineState]:
					state[pipelineState].map(
						t => {
							if (t.id === action.payload.candidate.id) {
								return action.payload.candidate;
							} else {
								return t;
							}
						}
					)
			};
		case PipelineActionTypes.MoveCandidateToState:
			const pipelineStateFrom = pipelineStateToValid(action.payload.candidate.pipelineState);
			const pipelineStateTo = pipelineStateToValid(action.payload.state);
			return {
				...state,
				[pipelineStateFrom]: state[pipelineStateFrom].filter(candidate => candidate.id !== action.payload.candidateId),
				[pipelineStateTo]: state[pipelineStateTo].concat([{...action.payload.candidate, pipelineState: action.payload.state}])
			};
		default:
			return state;
	}
}
