import gql from 'graphql-tag';
import * as Apollo from 'apollo-angular';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
export var AccountType;
(function (AccountType) {
    AccountType["ADMIN"] = "ADMIN";
    AccountType["BRAND"] = "BRAND";
    AccountType["CANDIDATE"] = "CANDIDATE";
    AccountType["SUPERADMIN"] = "SUPERADMIN";
})(AccountType || (AccountType = {}));
export var HostedPageType;
(function (HostedPageType) {
    HostedPageType["CHECKOUT_EXISTING"] = "CHECKOUT_EXISTING";
    HostedPageType["CHECKOUT_NEW"] = "CHECKOUT_NEW";
    HostedPageType["MANAGE_PAYMENT_SOURCES"] = "MANAGE_PAYMENT_SOURCES";
})(HostedPageType || (HostedPageType = {}));
export var Plan;
(function (Plan) {
    Plan["FULL_SERVICE"] = "FULL_SERVICE";
    Plan["MONTHLY"] = "MONTHLY";
    Plan["QUARTERLY"] = "QUARTERLY";
    Plan["YEARLY"] = "YEARLY";
})(Plan || (Plan = {}));
export var ResourceType;
(function (ResourceType) {
    ResourceType["COLOUR"] = "COLOUR";
    ResourceType["FILE"] = "FILE";
    ResourceType["IMAGE"] = "IMAGE";
    ResourceType["VIDEO"] = "VIDEO";
    ResourceType["YOUTUBE"] = "YOUTUBE";
})(ResourceType || (ResourceType = {}));
export var ScheduleState;
(function (ScheduleState) {
    ScheduleState["LIVE"] = "LIVE";
    ScheduleState["SAVED"] = "SAVED";
    ScheduleState["SCHEDULED"] = "SCHEDULED";
})(ScheduleState || (ScheduleState = {}));
export var SubscriptionState;
(function (SubscriptionState) {
    SubscriptionState["ACTIVE"] = "ACTIVE";
    SubscriptionState["BYPASS"] = "BYPASS";
    SubscriptionState["CANCELLED"] = "CANCELLED";
    SubscriptionState["CANCELLED_PAYMENT_FAILED"] = "CANCELLED_PAYMENT_FAILED";
    SubscriptionState["FREE_TRIAL"] = "FREE_TRIAL";
    SubscriptionState["NONE"] = "NONE";
})(SubscriptionState || (SubscriptionState = {}));
export const ResourceFragmentDoc = gql `
    fragment Resource on Resource {
  id
  type
  resource
}
    `;
export const ResourceArticleSectionFragmentDoc = gql `
    fragment ResourceArticleSection on ResourceArticleSection {
  id
  resource {
    ...Resource
  }
}
    ${ResourceFragmentDoc}`;
export const TextArticleSectionFragmentDoc = gql `
    fragment TextArticleSection on TextArticleSection {
  id
  text
}
    `;
export const ArticleSectionFragmentDoc = gql `
    fragment ArticleSection on ArticleSection {
  ...ResourceArticleSection
  ...TextArticleSection
}
    ${ResourceArticleSectionFragmentDoc}
${TextArticleSectionFragmentDoc}`;
export const ArticleFragmentDoc = gql `
    fragment Article on Article {
  id
  title
  header {
    ...Resource
  }
  sections {
    ...ArticleSection
  }
}
    ${ResourceFragmentDoc}
${ArticleSectionFragmentDoc}`;
export const AuthorFragmentDoc = gql `
    fragment Author on Employee {
  id
  name
  jobTitle
  avatar {
    ...Resource
  }
  brand {
    name
    logo {
      ...Resource
    }
  }
}
    ${ResourceFragmentDoc}`;
export const AuthoredArticleFragmentDoc = gql `
    fragment AuthoredArticle on Article {
  ...Article
  author {
    ...Author
  }
}
    ${ArticleFragmentDoc}
${AuthorFragmentDoc}`;
export const EmployeeFragmentDoc = gql `
    fragment Employee on Employee {
  id
  name
  jobTitle
  avatar {
    ...Resource
  }
}
    ${ResourceFragmentDoc}`;
export const LocationFragmentDoc = gql `
    fragment Location on Location {
  id
  name
  latitude
  longitude
}
    `;
export const PostFragmentDoc = gql `
    fragment Post on Post {
  id
  text
  media {
    ...Resource
  }
}
    ${ResourceFragmentDoc}`;
export const SkillFragmentDoc = gql `
    fragment Skill on Skill {
  id
  name
}
    `;
export const IndustryExperienceFragmentDoc = gql `
    fragment IndustryExperience on IndustryExperience {
  id
  name
}
    `;
export const ContentTargetingLocationFragmentDoc = gql `
    fragment ContentTargetingLocation on ContentTargetingLocation {
  location
  latitude
  longitude
  maxDistance
  includeRemoteWorkers
}
    `;
export const ContentTargetingFragmentDoc = gql `
    fragment ContentTargeting on ContentTargeting {
  skills {
    ...Skill
  }
  industryExperiences {
    ...IndustryExperience
  }
  location {
    ...ContentTargetingLocation
  }
}
    ${SkillFragmentDoc}
${IndustryExperienceFragmentDoc}
${ContentTargetingLocationFragmentDoc}`;
export const TargetedContentFragmentDoc = gql `
    fragment TargetedContent on Content {
  ...Article
  ...Post
  targeting {
    ...ContentTargeting
  }
}
    ${ArticleFragmentDoc}
${PostFragmentDoc}
${ContentTargetingFragmentDoc}`;
export const AnonymousLikerFragmentDoc = gql `
    fragment AnonymousLiker on AnonymousLiker {
  id
}
    `;
export const NamedLikerFragmentDoc = gql `
    fragment NamedLiker on NamedLiker {
  id
  name
}
    `;
export const LikerFragmentDoc = gql `
    fragment Liker on Liker {
  ...AnonymousLiker
  ...NamedLiker
}
    ${AnonymousLikerFragmentDoc}
${NamedLikerFragmentDoc}`;
export const LikeFragmentDoc = gql `
    fragment Like on Like {
  id
  liker {
    ...Liker
  }
  createdAt
}
    ${LikerFragmentDoc}`;
export const LikeActivityFragmentDoc = gql `
    fragment LikeActivity on Like {
  ...Like
  content {
    id
    ... on Article {
      header {
        ...Resource
      }
    }
    ... on Post {
      media {
        ...Resource
      }
    }
  }
}
    ${LikeFragmentDoc}
${ResourceFragmentDoc}`;
export const AuthoredPostFragmentDoc = gql `
    fragment AuthoredPost on Post {
  ...Post
  author {
    ...Author
  }
}
    ${PostFragmentDoc}
${AuthorFragmentDoc}`;
export const ContentHubStatsFragmentDoc = gql `
    fragment ContentHubStats on Brand {
  numViews
  numFollowers
  totalComments
  totalViews
  totalLikes
}
    `;
export const CommentFragmentDoc = gql `
    fragment Comment on Comment {
  id
  text
  commenter {
    ... on NamedCommenter {
      id
      name
    }
    ... on AnonymousCommenter {
      id
    }
  }
  createdAt
}
    `;
export const CommentActivityFragmentDoc = gql `
    fragment CommentActivity on Comment {
  ...Comment
  content {
    id
    ... on Article {
      header {
        ...Resource
      }
    }
    ... on Post {
      media {
        ...Resource
      }
    }
  }
}
    ${CommentFragmentDoc}
${ResourceFragmentDoc}`;
export const ContentFragmentDoc = gql `
    fragment Content on Content {
  ...Article
  ...Post
  author {
    ...Author
  }
  numLikes
  numComments
  numViews
  publishedAt
}
    ${ArticleFragmentDoc}
${PostFragmentDoc}
${AuthorFragmentDoc}`;
export const ContentWithCommentsFragmentDoc = gql `
    fragment ContentWithComments on Content {
  ...Content
  comments {
    ...Comment
    comments {
      ...Comment
    }
  }
}
    ${ContentFragmentDoc}
${CommentFragmentDoc}`;
export const UpdatedCommentsForContentFragmentDoc = gql `
    fragment UpdatedCommentsForContent on Content {
  id
  numComments
  comments {
    ...Comment
    comments {
      ...Comment
    }
  }
}
    ${CommentFragmentDoc}`;
export const EmployeeNotificationFragmentDoc = gql `
    fragment EmployeeNotification on EmployeeNotification {
  id
  read
  createdAt
  ... on NewContentCommentEmployeeNotification {
    id
    comment {
      id
      content {
        ... on Article {
          id
          title
        }
        ... on Post {
          id
          text
        }
      }
    }
  }
  ... on NewInterestedCandidateEmployeeNotification {
    id
    candidate {
      id
      avatar {
        ...Resource
      }
      name
    }
    role {
      id
      title
    }
    score
  }
  ... on NewMatchedCandidateEmployeeNotification {
    id
    candidate {
      id
      avatar {
        ...Resource
      }
      name
    }
    role {
      id
      title
    }
    score
  }
  ... on NewMessageEmployeeNotification {
    id
    message {
      participant {
        avatar {
          ...Resource
        }
        name
      }
      conversation {
        id
        role {
          id
          title
        }
      }
    }
  }
}
    ${ResourceFragmentDoc}`;
export const ChargebeeHostedPageFragmentDoc = gql `
    fragment ChargebeeHostedPage on ChargebeeHostedPage {
  created_at
  embed
  expires_at
  id
  object
  resource_version
  state
  type
  updated_at
  url
}
    `;
export const ChargebeePortalSessionFragmentDoc = gql `
    fragment ChargebeePortalSession on ChargebeePortalSession {
  id
  token
  access_url
  status
  created_at
  expires_at
  object
  customer_id
  linked_customers {
    object
    customer_id
    email
    has_billing_address
    has_payment_method
    has_active_subscription
  }
}
    `;
export const UpdateEmployeeDocument = gql `
    mutation UpdateEmployee($name: String!, $jobTitle: String!) {
  updateEmployee(input: {name: $name, jobTitle: $jobTitle}) {
    employee {
      ...Author
    }
  }
}
    ${AuthorFragmentDoc}`;
export class UpdateEmployeeGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = UpdateEmployeeDocument;
    }
}
UpdateEmployeeGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateEmployeeGQL_Factory() { return new UpdateEmployeeGQL(i0.ɵɵinject(i1.Apollo)); }, token: UpdateEmployeeGQL, providedIn: "root" });
export const UpdateEmployeeAvatarDocument = gql `
    mutation UpdateEmployeeAvatar($contentType: String!) {
  updateEmployeeAvatar(input: {contentType: $contentType}) {
    uploadUrl
  }
}
    `;
export class UpdateEmployeeAvatarGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = UpdateEmployeeAvatarDocument;
    }
}
UpdateEmployeeAvatarGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateEmployeeAvatarGQL_Factory() { return new UpdateEmployeeAvatarGQL(i0.ɵɵinject(i1.Apollo)); }, token: UpdateEmployeeAvatarGQL, providedIn: "root" });
export const AddBrandLocationDocument = gql `
    mutation AddBrandLocation($name: String!, $latitude: Float!, $longitude: Float!) {
  updateBrand(input: {locations: [{name: $name, latitude: $latitude, longitude: $longitude}]}) {
    brand {
      id
      locations {
        ...Location
      }
    }
  }
}
    ${LocationFragmentDoc}`;
export class AddBrandLocationGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = AddBrandLocationDocument;
    }
}
AddBrandLocationGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AddBrandLocationGQL_Factory() { return new AddBrandLocationGQL(i0.ɵɵinject(i1.Apollo)); }, token: AddBrandLocationGQL, providedIn: "root" });
export const GetBrandLocationsDocument = gql `
    query GetBrandLocations {
  me {
    employee {
      brand {
        id
        locations {
          ...Location
        }
      }
    }
  }
}
    ${LocationFragmentDoc}`;
export class GetBrandLocationsGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetBrandLocationsDocument;
    }
}
GetBrandLocationsGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetBrandLocationsGQL_Factory() { return new GetBrandLocationsGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetBrandLocationsGQL, providedIn: "root" });
export const UpdateTargetingForContentDocument = gql `
    mutation UpdateTargetingForContent($id: Int!, $skillIds: [Int!]!, $industryExperienceIds: [Int!]!, $location: ContentTargetingLocationInput) {
  updateContentTargeting(input: {id: $id, skillIds: $skillIds, industryExperienceIds: $industryExperienceIds, location: $location}) {
    content {
      targeting {
        ...ContentTargeting
      }
    }
  }
}
    ${ContentTargetingFragmentDoc}`;
export class UpdateTargetingForContentGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = UpdateTargetingForContentDocument;
    }
}
UpdateTargetingForContentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateTargetingForContentGQL_Factory() { return new UpdateTargetingForContentGQL(i0.ɵɵinject(i1.Apollo)); }, token: UpdateTargetingForContentGQL, providedIn: "root" });
export const GetTargetingForContentDocument = gql `
    query GetTargetingForContent($id: Int!) {
  me {
    employee {
      brand {
        content(id: $id) {
          id
          targeting {
            ...ContentTargeting
          }
        }
      }
    }
  }
}
    ${ContentTargetingFragmentDoc}`;
export class GetTargetingForContentGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetTargetingForContentDocument;
    }
}
GetTargetingForContentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetTargetingForContentGQL_Factory() { return new GetTargetingForContentGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetTargetingForContentGQL, providedIn: "root" });
export const CreateArticleDocument = gql `
    mutation CreateArticle($title: String!) {
  createArticle(input: {title: $title}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;
export class CreateArticleGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CreateArticleDocument;
    }
}
CreateArticleGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreateArticleGQL_Factory() { return new CreateArticleGQL(i0.ɵɵinject(i1.Apollo)); }, token: CreateArticleGQL, providedIn: "root" });
export const UpdateArticleDocument = gql `
    mutation UpdateArticle($articleId: Int!, $title: String!) {
  updateArticle(input: {articleId: $articleId, title: $title}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;
export class UpdateArticleGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = UpdateArticleDocument;
    }
}
UpdateArticleGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateArticleGQL_Factory() { return new UpdateArticleGQL(i0.ɵɵinject(i1.Apollo)); }, token: UpdateArticleGQL, providedIn: "root" });
export const UpdateArticleHeaderDocument = gql `
    mutation UpdateArticleHeader($articleId: Int!, $contentType: String, $youTubeUrl: String) {
  updateArticleHeader(input: {articleId: $articleId, contentType: $contentType, youTubeUrl: $youTubeUrl}) {
    mediaUploadUrl
  }
}
    `;
export class UpdateArticleHeaderGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = UpdateArticleHeaderDocument;
    }
}
UpdateArticleHeaderGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateArticleHeaderGQL_Factory() { return new UpdateArticleHeaderGQL(i0.ɵɵinject(i1.Apollo)); }, token: UpdateArticleHeaderGQL, providedIn: "root" });
export const SwapArticleSectionIndicesDocument = gql `
    mutation SwapArticleSectionIndices($articleId: Int!, $firstArticleSectionId: Int!, $secondArticleSectionId: Int!) {
  swapArticleSectionIndices(input: {articleId: $articleId, firstArticleSectionId: $firstArticleSectionId, secondArticleSectionId: $secondArticleSectionId}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;
export class SwapArticleSectionIndicesGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = SwapArticleSectionIndicesDocument;
    }
}
SwapArticleSectionIndicesGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SwapArticleSectionIndicesGQL_Factory() { return new SwapArticleSectionIndicesGQL(i0.ɵɵinject(i1.Apollo)); }, token: SwapArticleSectionIndicesGQL, providedIn: "root" });
export const RemoveArticleHeaderDocument = gql `
    mutation RemoveArticleHeader($articleId: Int!) {
  removeArticleHeader(input: {articleId: $articleId}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;
export class RemoveArticleHeaderGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = RemoveArticleHeaderDocument;
    }
}
RemoveArticleHeaderGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoveArticleHeaderGQL_Factory() { return new RemoveArticleHeaderGQL(i0.ɵɵinject(i1.Apollo)); }, token: RemoveArticleHeaderGQL, providedIn: "root" });
export const CreateTextArticleSectionDocument = gql `
    mutation CreateTextArticleSection($articleId: Int!, $text: String!) {
  createTextArticleSection(input: {articleId: $articleId, text: $text}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;
export class CreateTextArticleSectionGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CreateTextArticleSectionDocument;
    }
}
CreateTextArticleSectionGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreateTextArticleSectionGQL_Factory() { return new CreateTextArticleSectionGQL(i0.ɵɵinject(i1.Apollo)); }, token: CreateTextArticleSectionGQL, providedIn: "root" });
export const UpdateTextArticleSectionDocument = gql `
    mutation UpdateTextArticleSection($articleId: Int!, $sectionId: Int!, $text: String!) {
  updateTextArticleSection(input: {articleId: $articleId, sectionId: $sectionId, text: $text}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;
export class UpdateTextArticleSectionGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = UpdateTextArticleSectionDocument;
    }
}
UpdateTextArticleSectionGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateTextArticleSectionGQL_Factory() { return new UpdateTextArticleSectionGQL(i0.ɵɵinject(i1.Apollo)); }, token: UpdateTextArticleSectionGQL, providedIn: "root" });
export const CreateResourceArticleSectionDocument = gql `
    mutation CreateResourceArticleSection($articleId: Int!, $contentType: String, $youTubeUrl: String) {
  createResourceArticleSection(input: {articleId: $articleId, contentType: $contentType, youTubeUrl: $youTubeUrl}) {
    uploadUrl
  }
}
    `;
export class CreateResourceArticleSectionGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CreateResourceArticleSectionDocument;
    }
}
CreateResourceArticleSectionGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreateResourceArticleSectionGQL_Factory() { return new CreateResourceArticleSectionGQL(i0.ɵɵinject(i1.Apollo)); }, token: CreateResourceArticleSectionGQL, providedIn: "root" });
export const RemoveArticleSectionDocument = gql `
    mutation RemoveArticleSection($articleId: Int!, $sectionId: Int!) {
  removeArticleSection(input: {articleId: $articleId, sectionId: $sectionId}) {
    article {
      ...AuthoredArticle
    }
  }
}
    ${AuthoredArticleFragmentDoc}`;
export class RemoveArticleSectionGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = RemoveArticleSectionDocument;
    }
}
RemoveArticleSectionGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoveArticleSectionGQL_Factory() { return new RemoveArticleSectionGQL(i0.ɵɵinject(i1.Apollo)); }, token: RemoveArticleSectionGQL, providedIn: "root" });
export const CreatePostDocument = gql `
    mutation CreatePost($text: String!) {
  createPost(input: {text: $text}) {
    post {
      ...AuthoredPost
    }
  }
}
    ${AuthoredPostFragmentDoc}`;
export class CreatePostGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CreatePostDocument;
    }
}
CreatePostGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreatePostGQL_Factory() { return new CreatePostGQL(i0.ɵɵinject(i1.Apollo)); }, token: CreatePostGQL, providedIn: "root" });
export const UpdatePostDocument = gql `
    mutation UpdatePost($id: Int!, $text: String!) {
  updatePost(input: {id: $id, text: $text}) {
    post {
      ...AuthoredPost
    }
  }
}
    ${AuthoredPostFragmentDoc}`;
export class UpdatePostGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = UpdatePostDocument;
    }
}
UpdatePostGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdatePostGQL_Factory() { return new UpdatePostGQL(i0.ɵɵinject(i1.Apollo)); }, token: UpdatePostGQL, providedIn: "root" });
export const UpdatePostMediaDocument = gql `
    mutation UpdatePostMedia($id: Int!, $contentType: String, $youTubeUrl: String) {
  updatePostMedia(input: {id: $id, contentType: $contentType, youTubeUrl: $youTubeUrl}) {
    mediaUploadUrl
  }
}
    `;
export class UpdatePostMediaGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = UpdatePostMediaDocument;
    }
}
UpdatePostMediaGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdatePostMediaGQL_Factory() { return new UpdatePostMediaGQL(i0.ɵɵinject(i1.Apollo)); }, token: UpdatePostMediaGQL, providedIn: "root" });
export const RemovePostMediaDocument = gql `
    mutation RemovePostMedia($postId: Int!) {
  removePostMedia(input: {postId: $postId}) {
    post {
      ...AuthoredPost
    }
  }
}
    ${AuthoredPostFragmentDoc}`;
export class RemovePostMediaGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = RemovePostMediaDocument;
    }
}
RemovePostMediaGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemovePostMediaGQL_Factory() { return new RemovePostMediaGQL(i0.ɵɵinject(i1.Apollo)); }, token: RemovePostMediaGQL, providedIn: "root" });
export const GetLikesOfContentDocument = gql `
    query GetLikesOfContent($id: Int!) {
  me {
    employee {
      brand {
        content(id: $id) {
          numLikes
          ... on Article {
            likes(paging: {offset: 0, limit: 8}) {
              ...Like
            }
          }
          ... on Post {
            likes(paging: {offset: 0, limit: 8}) {
              ...Like
            }
          }
        }
      }
    }
  }
}
    ${LikeFragmentDoc}`;
export class GetLikesOfContentGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetLikesOfContentDocument;
    }
}
GetLikesOfContentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetLikesOfContentGQL_Factory() { return new GetLikesOfContentGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetLikesOfContentGQL, providedIn: "root" });
export const RemoveContentDocument = gql `
    mutation RemoveContent($contentId: Int!) {
  removeContent(input: {contentId: $contentId}) {
    success
  }
}
    `;
export class RemoveContentGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = RemoveContentDocument;
    }
}
RemoveContentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoveContentGQL_Factory() { return new RemoveContentGQL(i0.ɵɵinject(i1.Apollo)); }, token: RemoveContentGQL, providedIn: "root" });
export const PublishContentDocument = gql `
    mutation PublishContent($id: Int!) {
  publishContent(input: {id: $id}) {
    content {
      ...Content
    }
  }
}
    ${ContentFragmentDoc}`;
export class PublishContentGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = PublishContentDocument;
    }
}
PublishContentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublishContentGQL_Factory() { return new PublishContentGQL(i0.ɵɵinject(i1.Apollo)); }, token: PublishContentGQL, providedIn: "root" });
export const GetContentHubStatsDocument = gql `
    query GetContentHubStats {
  me {
    employee {
      brand {
        ...ContentHubStats
      }
    }
  }
}
    ${ContentHubStatsFragmentDoc}`;
export class GetContentHubStatsGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetContentHubStatsDocument;
    }
}
GetContentHubStatsGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetContentHubStatsGQL_Factory() { return new GetContentHubStatsGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetContentHubStatsGQL, providedIn: "root" });
export const GetContentActivityDocument = gql `
    query GetContentActivity($offset: Int!, $limit: Int!) {
  me {
    employee {
      brand {
        contentActivity(paging: {offset: $offset, limit: $limit}) {
          ...CommentActivity
          ...LikeActivity
        }
      }
    }
  }
}
    ${CommentActivityFragmentDoc}
${LikeActivityFragmentDoc}`;
export class GetContentActivityGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetContentActivityDocument;
    }
}
GetContentActivityGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetContentActivityGQL_Factory() { return new GetContentActivityGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetContentActivityGQL, providedIn: "root" });
export const GetOwnContentByIdDocument = gql `
    query GetOwnContentById($id: Int!) {
  me {
    employee {
      brand {
        content(id: $id) {
          ...Content
        }
      }
    }
  }
}
    ${ContentFragmentDoc}`;
export class GetOwnContentByIdGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetOwnContentByIdDocument;
    }
}
GetOwnContentByIdGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetOwnContentByIdGQL_Factory() { return new GetOwnContentByIdGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetOwnContentByIdGQL, providedIn: "root" });
export const GetOwnPublishedContentDocument = gql `
    query GetOwnPublishedContent($paging: OffsetPagingInput!) {
  me {
    employee {
      brand {
        publishedContent(paging: $paging) {
          ...Content
        }
      }
    }
  }
}
    ${ContentFragmentDoc}`;
export class GetOwnPublishedContentGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetOwnPublishedContentDocument;
    }
}
GetOwnPublishedContentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetOwnPublishedContentGQL_Factory() { return new GetOwnPublishedContentGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetOwnPublishedContentGQL, providedIn: "root" });
export const GetOwnDraftContentDocument = gql `
    query GetOwnDraftContent($paging: OffsetPagingInput!) {
  me {
    employee {
      brand {
        draftContent(paging: $paging) {
          ...Content
        }
      }
    }
  }
}
    ${ContentFragmentDoc}`;
export class GetOwnDraftContentGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetOwnDraftContentDocument;
    }
}
GetOwnDraftContentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetOwnDraftContentGQL_Factory() { return new GetOwnDraftContentGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetOwnDraftContentGQL, providedIn: "root" });
export const GetAuthorDetailsDocument = gql `
    query GetAuthorDetails {
  me {
    employee {
      ...Author
    }
  }
}
    ${AuthorFragmentDoc}`;
export class GetAuthorDetailsGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetAuthorDetailsDocument;
    }
}
GetAuthorDetailsGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetAuthorDetailsGQL_Factory() { return new GetAuthorDetailsGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetAuthorDetailsGQL, providedIn: "root" });
export const CommentOnContentDocument = gql `
    mutation CommentOnContent($contentId: Int!, $text: String!) {
  commentOnContent(input: {contentId: $contentId, text: $text}) {
    content {
      ...UpdatedCommentsForContent
    }
  }
}
    ${UpdatedCommentsForContentFragmentDoc}`;
export class CommentOnContentGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CommentOnContentDocument;
    }
}
CommentOnContentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommentOnContentGQL_Factory() { return new CommentOnContentGQL(i0.ɵɵinject(i1.Apollo)); }, token: CommentOnContentGQL, providedIn: "root" });
export const CommentOnCommentDocument = gql `
    mutation CommentOnComment($contentId: Int!, $commentId: Int!, $text: String!) {
  commentOnComment(input: {contentId: $contentId, commentId: $commentId, text: $text}) {
    content {
      ...UpdatedCommentsForContent
    }
  }
}
    ${UpdatedCommentsForContentFragmentDoc}`;
export class CommentOnCommentGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CommentOnCommentDocument;
    }
}
CommentOnCommentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommentOnCommentGQL_Factory() { return new CommentOnCommentGQL(i0.ɵɵinject(i1.Apollo)); }, token: CommentOnCommentGQL, providedIn: "root" });
export const GetContentWithCommentsDocument = gql `
    query GetContentWithComments($id: Int!) {
  me {
    employee {
      id
      brand {
        id
        content(id: $id) {
          ...ContentWithComments
        }
      }
    }
  }
}
    ${ContentWithCommentsFragmentDoc}`;
export class GetContentWithCommentsGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetContentWithCommentsDocument;
    }
}
GetContentWithCommentsGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetContentWithCommentsGQL_Factory() { return new GetContentWithCommentsGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetContentWithCommentsGQL, providedIn: "root" });
export const GetIndustryExperiencesDocument = gql `
    query GetIndustryExperiences {
  industryExperiences {
    ...IndustryExperience
  }
}
    ${IndustryExperienceFragmentDoc}`;
export class GetIndustryExperiencesGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetIndustryExperiencesDocument;
    }
}
GetIndustryExperiencesGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetIndustryExperiencesGQL_Factory() { return new GetIndustryExperiencesGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetIndustryExperiencesGQL, providedIn: "root" });
export const GetNotificationsDocument = gql `
    query GetNotifications {
  me {
    employee {
      id
      notifications {
        ...EmployeeNotification
      }
    }
  }
}
    ${EmployeeNotificationFragmentDoc}`;
export class GetNotificationsGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetNotificationsDocument;
    }
}
GetNotificationsGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetNotificationsGQL_Factory() { return new GetNotificationsGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetNotificationsGQL, providedIn: "root" });
export const NotificationReceivedDocument = gql `
    subscription NotificationReceived {
  notificationReceived {
    ...EmployeeNotification
  }
}
    ${EmployeeNotificationFragmentDoc}`;
export class NotificationReceivedGQL extends Apollo.Subscription {
    constructor() {
        super(...arguments);
        this.document = NotificationReceivedDocument;
    }
}
NotificationReceivedGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationReceivedGQL_Factory() { return new NotificationReceivedGQL(i0.ɵɵinject(i1.Apollo)); }, token: NotificationReceivedGQL, providedIn: "root" });
export const CreateHostedPageDocument = gql `
    mutation CreateHostedPage($type: HostedPageType!, $plan: Plan) {
  createChargebeeHostedPage(input: {type: $type, plan: $plan}) {
    ...ChargebeeHostedPage
  }
}
    ${ChargebeeHostedPageFragmentDoc}`;
export class CreateHostedPageGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CreateHostedPageDocument;
    }
}
CreateHostedPageGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreateHostedPageGQL_Factory() { return new CreateHostedPageGQL(i0.ɵɵinject(i1.Apollo)); }, token: CreateHostedPageGQL, providedIn: "root" });
export const CreatePortalSessionDocument = gql `
    mutation CreatePortalSession {
  createChargebeePortalSession {
    ...ChargebeePortalSession
  }
}
    ${ChargebeePortalSessionFragmentDoc}`;
export class CreatePortalSessionGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CreatePortalSessionDocument;
    }
}
CreatePortalSessionGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreatePortalSessionGQL_Factory() { return new CreatePortalSessionGQL(i0.ɵɵinject(i1.Apollo)); }, token: CreatePortalSessionGQL, providedIn: "root" });
export const CreateOfflineSubscriptionDocument = gql `
    mutation CreateOfflineSubscription($plan: Plan!) {
  createOfflineSubscription(input: {plan: $plan}) {
    success
  }
}
    `;
export class CreateOfflineSubscriptionGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CreateOfflineSubscriptionDocument;
    }
}
CreateOfflineSubscriptionGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreateOfflineSubscriptionGQL_Factory() { return new CreateOfflineSubscriptionGQL(i0.ɵɵinject(i1.Apollo)); }, token: CreateOfflineSubscriptionGQL, providedIn: "root" });
export const ReactivateOfflineSubscriptionDocument = gql `
    mutation ReactivateOfflineSubscription {
  reactivateOfflineSubscription {
    success
  }
}
    `;
export class ReactivateOfflineSubscriptionGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = ReactivateOfflineSubscriptionDocument;
    }
}
ReactivateOfflineSubscriptionGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReactivateOfflineSubscriptionGQL_Factory() { return new ReactivateOfflineSubscriptionGQL(i0.ɵɵinject(i1.Apollo)); }, token: ReactivateOfflineSubscriptionGQL, providedIn: "root" });
export const RequestExternalPaymentDocument = gql `
    mutation RequestExternalPayment($input: RequestExternalPaymentInput!) {
  requestExternalPayment(input: $input) {
    success
  }
}
    `;
export class RequestExternalPaymentGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = RequestExternalPaymentDocument;
    }
}
RequestExternalPaymentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RequestExternalPaymentGQL_Factory() { return new RequestExternalPaymentGQL(i0.ɵɵinject(i1.Apollo)); }, token: RequestExternalPaymentGQL, providedIn: "root" });
export const CreateChargebeeHostedPageForExternalFinancierDocument = gql `
    mutation CreateChargebeeHostedPageForExternalFinancier($input: CreateChargebeeHostedPageForExternalFinancierInput!) {
  createChargebeeHostedPageForExternalFinancier(input: $input) {
    ...ChargebeeHostedPage
  }
}
    ${ChargebeeHostedPageFragmentDoc}`;
export class CreateChargebeeHostedPageForExternalFinancierGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CreateChargebeeHostedPageForExternalFinancierDocument;
    }
}
CreateChargebeeHostedPageForExternalFinancierGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreateChargebeeHostedPageForExternalFinancierGQL_Factory() { return new CreateChargebeeHostedPageForExternalFinancierGQL(i0.ɵɵinject(i1.Apollo)); }, token: CreateChargebeeHostedPageForExternalFinancierGQL, providedIn: "root" });
export const CreateChargebeePortalSessionForExternalFinancierDocument = gql `
    mutation CreateChargebeePortalSessionForExternalFinancier($input: CreateChargebeePortalSessionForExternalFinancierInput!) {
  createChargebeePortalSessionForExternalFinancier(input: $input) {
    ...ChargebeePortalSession
  }
}
    ${ChargebeePortalSessionFragmentDoc}`;
export class CreateChargebeePortalSessionForExternalFinancierGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = CreateChargebeePortalSessionForExternalFinancierDocument;
    }
}
CreateChargebeePortalSessionForExternalFinancierGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreateChargebeePortalSessionForExternalFinancierGQL_Factory() { return new CreateChargebeePortalSessionForExternalFinancierGQL(i0.ɵɵinject(i1.Apollo)); }, token: CreateChargebeePortalSessionForExternalFinancierGQL, providedIn: "root" });
export const NotifyEmployeeOfExternalPaymentDocument = gql `
    mutation NotifyEmployeeOfExternalPayment($input: NotifyEmployeeOfExternalPaymentInput!) {
  notifyEmployeeOfExternalPayment(input: $input) {
    success
  }
}
    `;
export class NotifyEmployeeOfExternalPaymentGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = NotifyEmployeeOfExternalPaymentDocument;
    }
}
NotifyEmployeeOfExternalPaymentGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotifyEmployeeOfExternalPaymentGQL_Factory() { return new NotifyEmployeeOfExternalPaymentGQL(i0.ɵɵinject(i1.Apollo)); }, token: NotifyEmployeeOfExternalPaymentGQL, providedIn: "root" });
export const GetBrandSubscriptionStateDocument = gql `
    query GetBrandSubscriptionState {
  me {
    employee {
      brand {
        subscriptionState
      }
    }
  }
}
    `;
export class GetBrandSubscriptionStateGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetBrandSubscriptionStateDocument;
    }
}
GetBrandSubscriptionStateGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetBrandSubscriptionStateGQL_Factory() { return new GetBrandSubscriptionStateGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetBrandSubscriptionStateGQL, providedIn: "root" });
export const GetExternalFinancierSubscriptionStateDocument = gql `
    query GetExternalFinancierSubscriptionState($token: String!) {
  externalFinancierSubscriptionState(input: {token: $token})
}
    `;
export class GetExternalFinancierSubscriptionStateGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = GetExternalFinancierSubscriptionStateDocument;
    }
}
GetExternalFinancierSubscriptionStateGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetExternalFinancierSubscriptionStateGQL_Factory() { return new GetExternalFinancierSubscriptionStateGQL(i0.ɵɵinject(i1.Apollo)); }, token: GetExternalFinancierSubscriptionStateGQL, providedIn: "root" });
export const SubscriptionStateChangedDocument = gql `
    subscription SubscriptionStateChanged {
  subscriptionStateChanged
}
    `;
export class SubscriptionStateChangedGQL extends Apollo.Subscription {
    constructor() {
        super(...arguments);
        this.document = SubscriptionStateChangedDocument;
    }
}
SubscriptionStateChangedGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionStateChangedGQL_Factory() { return new SubscriptionStateChangedGQL(i0.ɵɵinject(i1.Apollo)); }, token: SubscriptionStateChangedGQL, providedIn: "root" });
