import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Candidate} from '@caroo/shared/models';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PipelineService {

	constructor(private http: HttpClient) {
	}

	getPipelineCandidates(roleId: number, state: string): Observable<Candidate[]> {
		return this.http.get<Candidate[]>(`${environment.baseUrl}/roles/${roleId}/pipelineV2?pipelineState=${state}`);
	}

	moveCandidateToState(roleId: number, candidateId: number, state: string) {
		return this.http.put(`${environment.baseUrl}/roles/${roleId}/candidates/${candidateId}/pipelineState`, {pipelineState: state});
	}
}
